import React, { useState, useEffect } from 'react';
import { Col, Image, Row, Card } from 'react-bootstrap'
import Miami from '../assets/images/miami.jpg'
import LA from '../assets/images/LA.jpg'
import Newyork from '../assets/images/NY.jpeg'
import Property from '../assets/images/product_charges.png'
import General from '../assets/images/general.png'
import Assets from '../assets/images/assets_.png'
import ForSale from '../assets/images/for_sale.jpg'
import '../assets/scss/PropertiesCities.scss'
import { Link } from 'react-router-dom'
import GetStartedOffcanvas from '../components/GetStartedOffcanvas';
import { serverUrl, landlordId } from '../serverUrl';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
function PropertiesCities() {


    const [propertiesCounty, setPropertiesCounty] = useState([])
    const [propertiesCount, setPropertiesCount] = useState([])

    useEffect(() => {
        serverUrl.get(`/landlord/alliance-county-properties`)
            .then((res) => {
                setPropertiesCounty(res.data.results)
                setPropertiesCount(res.data.count)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    const [showOffCanvas, setShowOffCanvas] = useState(false);

    const toggleOffCanvas = () => {
        setShowOffCanvas(!showOffCanvas);
    };
    const settings = {
        dots: true,
        infinite: false,
        speed: 3000,
        slidesToShow: 5,
        slidesToScroll: 1,
        autoplay: true,
        autoplaySpeed: 3000,
        responsive: [
            {
                breakpoint: 768,
                settings: {
                    slidesToShow: 1,
                    slidesToScroll: 1,
                },
            },
        ],
    };
    return (
        <>
            {propertiesCount === 0 ?
                <>
                </> :
                <div className='properties_cities'>

                    <h2 className='mt-3 fw-bold fs-1 mb-1'>What we offer</h2>
                    <p>Get the <b className='fw-bold text-warning' onClick={toggleOffCanvas} >Best Services</b> from us</p>

                    <GetStartedOffcanvas show={showOffCanvas} onClose={toggleOffCanvas} />



                    <Row className=''>
                        <Col md={8}>
                            <Link to='/services'>
                                <div className='image-cities-container'>
                                    <Image src={Property} alt='New York' />
                                    <div className='bottomImageDisplay'>
                                        <span className='imageText'>PROPERTY & SERVICE CHARGE MANAGEMENT</span>
                                    </div>
                                </div>
                            </Link>

                        </Col>


                        <Col md={4}>
                            <Link to='/services'>

                                <div className='image-cities-containertwo'>
                                    <Image src={General} />
                                    <div className='bottomImageDisplay'>
                                        <span className='imageText'>PROPERTY & ASSET VALUATION</span>
                                    </div>
                                </div>
                            </Link>
                        </Col>

                        <Col md={4}>
                            <Link to='/comming-soon'>

                                <div className='image-full'>
                                    <Image src={ForSale} style={{width:"-webkit-fill-available"}} />
                                    <div className='bottomImageDisplay'>
                                        <span className='imageText'>PROPERTY AND LAND LISTING</span>
                                    </div>
                                </div>
                            </Link>
                        </Col>

                        <Col md={8}>
                            <Link to='/services'>

                                <div className='image-full'>
                                    <Image src={Assets} />
                                    <div className='bottomImageDisplay'>
                                        <span className='imageText'>REAL ESTATE CONSULTANCY</span>
                                    </div>
                                </div>
                            </Link>
                        </Col>

                    

                    </Row>

                    {/* display the cities */}
                    <div className='d-none'>

                        <h2 className='mt-3 fw-bold fs-1'>Find properties in these counties</h2>
                        <p>Unlocking Your <b className='fw-bold text-warning' onClick={toggleOffCanvas}>Perfect Place </b>,County by County.</p>
                        <Row style={{ width: "95%", padding: "1.5rem" }}>
                            <Slider {...settings}>
                                {propertiesCounty.map((city) => (
                                    <Col lg={2} md={2} sm={6} xm={12} className='columCities' key={city.county_name}>
                                        <Link to={`/listing-page/${city.county_name}`} className='text-decoration-none'>
                                            <div className='image_col'>
                                                <div className='image_container' style={{ backgroundImage: `url(${Newyork})` }}>
                                                    <Card className='cities_card'>
                                                        <h3>{city.county_name}</h3>
                                                        <p>{city.property_count} Properties</p>
                                                    </Card>
                                                </div>
                                            </div>
                                        </Link>
                                    </Col>


                                ))}
                            </Slider>
                        </Row>
                    </div>
                </div>}
        </>
    )
}

export default PropertiesCities