import React, { useState, useEffect } from "react";
import { Map, Marker, GoogleApiWrapper } from 'google-maps-react';
import NavigationBar from '../components/NavigationBar';
import MapImage from '../assets/images/listings.png'
import Footer from '../components/Footer';
import { Nav, Navbar, NavDropdown, Container, Image, Dropdown, Modal, Button, Form, InputGroup } from 'react-bootstrap';
import TopMenuFilter from '../components/TopMenuFilter';
import { Link, useParams } from "react-router-dom";
import MapComponent from '../components/MapComponent';
import { serverUrl, landlordId } from '../serverUrl';
import AllMap from "../components/AllMap";

function HouseLocation() {
  const { id } = useParams();

  const [apartmentLocation, setApartmentLocation] = useState(null);
  const [coordinatesArray, setCoordinatesArray] = useState([]);

  useEffect(() => {
    if (id === 'listing') {
      serverUrl.get(`/landlord/all-landlords`)
        .then((res) => {
          const filteredApartments = res.data.results.filter((apartment) => apartment.is_listed == true);
         

          const updatedCoordinatesArray = filteredApartments.map((apartment) => ({
            latitude: apartment.latitude,
            longitude: apartment.longitude,
            min_rent: apartment.min_rent,
          }));
  
          setCoordinatesArray(filteredApartments);

          // Set state or perform other operations as needed
          // setLandlordApartment(filteredApartments);
          // setCountApartment(res.data.count);
        })
        .catch((error) => {
          console.log(error);
        });

    }
    else {
      serverUrl.get(`/landlord/alliance-specific-apartment?property_id=${id}`)
        .then((res) => {
          setApartmentLocation(res.data.results[0]);
        })
        .catch((error) => {
          console.log(error);
        });
    }




  }, [id]);
  return (
    <>
      <NavigationBar />
      <TopMenuFilter />
      <div style={{ width: '100%', height: '100%', marginTop: "13rem" }}>
        {id === 'listing' ?
          coordinatesArray && <AllMap coordinates={coordinatesArray} className='mapLoactionDisplay' />
          :
          apartmentLocation && <MapComponent location={apartmentLocation} className='mapLoactionDisplay' />

        }
      </div>
      {id === 'listing' ?
        <Button className='text-white bg-dark border-0 bottomButtonMap'>
          <Link to={`/listing-properties`} className='text-white text-decoration-none'>
            Show Listings

          </Link>
          <Image src={MapImage} style={{ width: "15%", marginLeft: "0.4rem" }} />
        </Button>
        :
        <Button className='text-white bg-dark border-0 bottomButtonMap'>
          <Link to={`/selected-property/${id}`} className='text-white text-decoration-none'>
            Show Listings

          </Link>
          <Image src={MapImage} style={{ width: "15%", marginLeft: "0.4rem" }} />
        </Button>


      }

      {/* <div className='mapFooter'>
        <Footer />

      </div> */}

    </>)
}

export default HouseLocation



