import React, { useState, useEffect } from "react";
import { Link, useParams } from "react-router-dom";
import {
  Button,
  Card,
  Col,
  Row,
  Image,
  Modal,
  Form,
  Dropdown,
  Carousel,
  Container,
  FormControl,
} from "react-bootstrap";
import "../assets/scss/SelectedHouse.scss";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import NavigationBar from "../components/NavigationBar";
// auth imports
import { useNavigate } from "react-router";
import Footer from "../components/Footer";

import HouseLocation from "./HouseLocation";
import Star from "../assets/images/star.png";
import Grid from "../assets/images/grid.png";
import User from "../assets/images/logo_1.png";
import Person from "../assets/images/person_1.png";
import Person1 from "../assets/images/person_2.png";
import Top from "../assets/images/office-desk.png";
import Bottom from "../assets/images/swimming.png";
import MapComponent from "../components/MapComponent";
import { serverUrl } from "../serverUrl";
import Twitter from "../assets/images/twitter.png";
import Facebook from "../assets/images/facebook.png";
import Instagram from "../assets/images/instagram.png";
import Mail from "../assets/images/gmail.png";
import Whatsapp from "../assets/images/whatsapp.png";
import Paperclip from "../assets/images/paperclip.png";

import { toast } from "react-toastify";
import { jwtDecode } from "jwt-decode";

function SelectedPropertAlliance() {
  const { id } = useParams();
  // const [showModal, setShowModal] = useState(false);
  const [showModalHouse, setShowModalHouse] = useState(false);
  const [showModalRate, setShowModalRate] = useState(false);

  // modal for editing the details of the tenant
  const [showModalEdit, setShowModalEdit] = useState(false);
  const [houseTenant, setHouseTenant] = useState([]);

  // the current date format in yyyy-mm-dd
  let formatDate = new Date().toISOString().split("T")[0];

  // remove tenant
  const [showModalDeleteTenant, setShowModalDeleteTenant] = useState(false);
  const [tenantRemove, setTenantRemove] = useState(null);
  const [tenantIdOccupancy, setTenantIdOccupancy] = useState(null);
  const [houseId, setHouseId] = useState(null);

  const handleOpenDeleteTenant = () => {
    setShowModalDeleteTenant(true);
  };
  const handleCloseModalDeleteTenant = () => {
    setShowModalDeleteTenant(false);
  };
  const handleCloseModalReloadTenantDelete = () => {
    setShowModalDeleteTenant(false);
    window.location.reload();
  };

  const [showModalImageCarousel, setShowModalImageCarousel] = useState(false);
  const [selectedImageIndexCarousel, setSelectedImageIndexCarousel] =
    useState(0);

  const handleImageClickImageCarousel = (index) => {
    setSelectedImageIndexCarousel(index);
    setShowModalImageCarousel(true);
  };

  const handleCloseModalImageCarousel = () => {
    setShowModalImageCarousel(false);
  };

  const handleImageSelectImageCarousel = (index) => {
    setSelectedImageIndexCarousel(index);
  };

  // loading status
  const [isLoading, setIsLoading] = useState(true);

  // form data for tenant details
  const [formDataTenant, setFormDataTenant] = useState({
    full_name: "",
    phone_number: "",
  });
  const [landlord, setLandlord] = useState("");

  // house selected
  // const [houseSelected, setHouseSelected] = useState([])
  const [houseSelectedApartment, setHouseSelectedApartment] = useState("");
  const [houseApartment, setHouseApartment] = useState("");

  // for kodi score purpose
  const [tenantScore, setTenantScore] = useState("");
  const [tenantId, setTenantId] = useState("");
  const [occupancySet, setOccupancy] = useState({});
  const [rentStatus, setRentStatus] = useState({});

  const [houseSelected, setHouseSelected] = useState([]);
  const [houseSelectedAmenities, setHouseSelectedAmenities] = useState([]);
  const [houseSelectedImages, setHouseSelectedImages] = useState([]);
  const [roomImages, setRoomImages] = useState([]);
  const nearby = [
    {
      name: "Church",
      distance: "120m",
      image: "https://kodinyumba.app/media/church.png",
    },
    {
      name: "Hospital",
      distance: "500m",
      image: "https://kodinyumba.app/media/hospital.png",
    },
    {
      name: "Mall",
      distance: "640m",
      image: "https://kodinyumba.app/media/shopping-center.png",
    },
    {
      name: "School",
      distance: "20m",
      image: "https://kodinyumba.app/media/school.png",
    },
    {
      name: "Mosque",
      distance: "120m",
      image: "https://kodinyumba.app/media/mosque.png",
    },
    {
      name: "Tarmac Road",
      distance: "50m",
      image: "https://kodinyumba.app/media/road.png",
    },
    {
      name: "Police Station",
      distance: "400m",
      image: "https://kodinyumba.app/media/police-station.png",
    },
  ];
  const [nearbyFacilities, setNearbyFacilities] = useState([]);
  const [rulesSafety, setSafety] = useState([]);
  const [rulesRental, setRental] = useState([]);
  const [rulesProperty, setProperty] = useState([]);
  const [rules, setRules] = useState([]);

  const [heartState, setHeartState] = useState({});

  useEffect(() => {
    serverUrl
      .get(`/landlord/alliance-specific-apartment?property_id=${id}`)
      .then((res) => {
        setHouseSelected(res.data.results[0]);
        setHouseSelectedAmenities(res.data.results[0].amenities);
        setHouseSelectedImages(res.data.results[0].property_image);
        const apartmentid = res.data.results.property_image;
        // the nearby facilities for that unit
        serverUrl
          .get(
            `/landlord/alliance-facilities-apartment-allowany?apartment_id=${apartmentid}`
          )
          .then((res) => {
            setNearbyFacilities(res.data.results);
          })
          .catch((error) => {
            console.log(error);
          });

        // the rules for that apartment
        serverUrl
          .get(`/landlord/rules-allow-any/?apartment=${apartmentid}`)
          .then((res) => {
            setSafety(
              res.data.results.filter((safety) => safety.category === "safety")
            );
            setRental(
              res.data.results.filter(
                (rental) => rental.category === "rental_policy"
              )
            );
            setProperty(
              res.data.results.filter(
                (property) => property.category === "property_rules"
              )
            );
            setRules(res.data.count);
          })
          .catch((error) => {
            console.log(error);
          });
      })
      .catch((error) => {
        console.log(error);
      });

    // get the room images
    serverUrl
      .get(`/landlord/alliance-unit-rooms-allow?house_id=${id}`)
      .then((res) => {
        setRoomImages(res.data.results);
      })
      .catch((error) => {
        console.log(error);
      });
    const accessToken = localStorage.getItem("authTokens");

    if (accessToken) {
      // check first if user is logged in
      const parsedTokens = JSON.parse(accessToken);
      const access = parsedTokens.access;
      // headers access token
      const config = {
        headers: { Authorization: `Bearer ${access}` },
      };
      // decoding the token so that i can get the user id
      const decodedToken = jwtDecode(accessToken);
      const userId = decodedToken.user_id;
      // Fetch liked units for the user based on their userId.
      serverUrl
        .get(`/landlord/viewers-favourites/?user=${userId}`, config)
        .then((res) => {
          // Process the response to determine which units are liked by the user.
          const likedUnits = res.data.results.map(
            (likedUnit) => likedUnit.house
          );

          // Update the heartState based on the likedUnits.
          const newHeartState = { ...heartState };
          likedUnits.forEach((unitId) => {
            newHeartState[unitId] = true;
          });
          setHeartState(newHeartState);
        })
        .catch((error) => {
          console.log(error);
        });
    }
  }, []);

  const settings = {
    dots: false,
    infinite: true,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 1,
    autoplay: true,
    autoplaySpeed: 10000,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 1,
          slidesToScroll: 1,
        },
      },
    ],
    // fade:true,
  };
  const textSet = [
    "Bedroom",
    "Bathroom",
    "Kitchen",
    "Living Room",
    "Master Bedroom",
  ];
  const settingsSpecial = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 2,
    slidesToScroll: 2,
    appendDots: (dots) => (
      <div style={{ position: "absolute", top: "10px", right: "10px" }}>
        <ul style={{ margin: "0" }}>{dots}</ul>
      </div>
    ),
  };

  // Get the house object with the matching id
  // const imageDemo = [House6, House7, House8];
  const imageDemo = [];



  // Setting up the image
  const [currentImage, setCurrentImage] = useState(0);
  const [imageLength, setImageLength] = useState(0);
  const [imagesUnit, setImagesUnit] = useState([]);

  const [backgroundImages, setBackgroundImages] = useState({});
  const [imageSet, setImageSet] = useState([]);

  useEffect(() => {
    const getRandomImage = () => {
      const usedImages = Object.values(backgroundImages);
      const availableImages = Array.from(
        { length: 21 },
        (_, index) => index + 1
      );
      const remainingImages = availableImages.filter(
        (image) => !usedImages.includes(image)
      );
      const randomNumber =
        remainingImages[Math.floor(Math.random() * remainingImages.length)];
      const imageUrl = `https://kodinyumba.app/media/Stock/House/house${randomNumber}.jpg`;
      return imageUrl;
    };

    const updateBackgroundImages = () => {
      const updatedImages = { ...backgroundImages };
      const selectedHouses = [];

      // Generate random images for the first three houses
      for (let i = 0; i < 4; i++) {
        const imageUrl = getRandomImage();
        selectedHouses.push(imageUrl);

        // Update the updatedImages object
        updatedImages[i] = imageUrl;
      }

      // Push the selected images into imageDemo
      imageDemo.push(...selectedHouses);
      setImageSet(imageDemo);

      setBackgroundImages(updatedImages);
      setIsLoading(false);
    };

    updateBackgroundImages();

    // Set up the carousel to cycle through images
    const interval = setInterval(() => {
      setCurrentImage((prevImage) => (prevImage + 1) % imageDemo.length);
    }, 5000); // Change the interval as needed

    return () => clearInterval(interval); // Clean up the interval
  }, []);

  const selectedUnit = houseSelected[0];
  const idHouse = parseInt(id);

  const [showSidebar, setShowSidebar] = useState(true);
  function toggleSidebar() {
    setShowSidebar(!showSidebar);
  }

  // modal for editing the details of the tenant

  const handleOpenModalEdit = () => {
    setShowModalEdit(true);
  };

  const handleCloseModalEdit = () => {
    setShowModalEdit(false);
  };

  // counting the number of digits inserted on the phone number
  const digitCount = formDataTenant.phone_number.replace(/[^0-9]/g, "").length;
  // the data that will be posted to the api is as follows

  const handleOpenModalRate = () => {
    setShowModalRate(true);
  };

  const handleCloseModalRate = () => {
    setShowModalRate(false);
  };
  // done button reloading the page
  const handleCloseModalReloadRate = () => {
    setShowModalRate(false);
    window.location.reload();
  };
  // form data for tenant details ratings
  const [formDataTenantRate, setFormDataTenantRate] = useState({
    comment: "",
    rating: "",
  });

  // onchange functionality on rating tenant
  const handleChangeTenantRate = (event) => {
    const { name, value } = event.target;

    setFormDataTenantRate((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // onchange functionality on adding tenant
  const handleChangeTenant = (event) => {
    const { name, value } = event.target;
    // limit phone number input to 10 digits
    if (name === "phone_number") {
      const digitOnly = value.replace(/[^0-9]/g, "");
      setFormDataTenant((prevFormData) => ({
        ...prevFormData,
        [name]: digitOnly.substring(0, 10),
      }));
    } else {
      setFormDataTenant((prevFormData) => ({
        ...prevFormData,
        [name]: value,
      }));
    }
  };

  // form data to be sent to backend on eadding the House
  const [formDataHouse, setFormDataHouse] = useState({
    house_number: "",
    total_rooms: "",
    due_date: "",
    bedroom: "",
    deposit: "",
    bathroom: "",
    rent: "",
    type_of_house: "",
  });
  // onchange functionality on editing a  house
  const handleChangeHouse = (event) => {
    const { name, value } = event.target;
    setFormDataHouse((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };

  // now display the stars based on the score they have obtained

  const scoreParts = tenantScore.split("/");
  const scoreKodi = scoreParts[0];
  const dividedScore = parseFloat(scoreParts[0]) / 2;

  const starCount = Math.floor(dividedScore);
  // give them default half stars if for their score
  const hasHalfStar = dividedScore < 1;

  const stars = [];
  for (let i = 0; i < 5; i++) {
    if (i < starCount) {
      stars.push(<i className="bi bi-star-fill"></i>);
    } else if (i === starCount && hasHalfStar) {
      stars.push(<i className="bi bi-star-half"></i>);
    } else {
      stars.push(<i className="bi bi-star"></i>);
    }
  }

  // when user clicks call then open the call
  const handleClickPhone = () => {
    window.location.href = `tel:${houseTenant.phone_number}`;
  };


  // send reminder for the landlord
  const [remindersModal, setRemindersModal] = useState(false);

  // modal for filtering on the month
  const handleReminderModalOpen = () => {
    setRemindersModal(true);
  };

  const handleReminderModalClose = () => {
    setRemindersModal(false);
  };
  const handleReminderModalReload = () => {
    setRemindersModal(false);
    window.location.reload();
  };

  const [selectedOptionReminder, setSelectedOptionReminder] = useState(null);
  const [addTemplate, setAddTemplate] = useState(false);

  const handleOptionSelect = (option) => {
    setSelectedOptionReminder(option);
    setAddTemplate(false);
  };

  const handleAddTemplate = () => {
    setSelectedOptionReminder("");
    setAddTemplate(true);
  };
  const [showModal, setShowModal] = useState(false);
  const [selectedImageIndex, setSelectedImageIndex] = useState(0);

  const handleAmenitiesOpen = () => {
    setShowModal(true);
  };
  const handleAmenitiesClose = () => {
    setShowModal(false);
  };

  // house carousel
  const settingsImageMobile = {
    dots: true,
    infinite: false,
    speed: 500,
    slidesToShow: 1,
    slidesToScroll: 1,
  };

  // mobile view images carousel


  // get the ammenitues for that unit
  const [unitAmenities, setUnitAmenities] = useState([]);
  const [currentUser, setCurrentUser] = useState([])

  useEffect(() => {
    // get the amenities
    serverUrl
      .get(`/landlord/alliance-unit-amenities-allowany?house_id=${id}`)
      .then((res) => {
        setUnitAmenities(res.data.results);
      })
      .catch((error) => {
        console.log(error);
      });

    // if the user is logged in get the details and display them
    if (accessToken) {
      // Check if the user is logged in.
      const parsedTokens = JSON.parse(accessToken);
      const access = parsedTokens.access;

      // Headers access token.
      const config = {
        headers: { Authorization: `Bearer ${access}` },
      };

      // Decoding the token to get the user id.
      const decodedToken = jwtDecode(accessToken);
      const userId = decodedToken.user_id;
      serverUrl.get(`/users/users-details/${userId}/`, config)
        .then((res) => {
          setCurrentUser(res.data)
        })
        .catch((error) => {
          console.log(error)
        })

    }
  }, [id]);

  const [show, setShow] = useState(false);

  const handleClose = () => setShow(false);
  const handleShow = () => setShow(true);

  const [copied, setCopied] = useState(false);

  const url = `https://alliancerealtors.co.ke/#/selected-house/${id}`
  const photourl = `https://alliancerealtors.co.ke/#/unit/${id}`;


  const handleCopyLink = () => {
    const textToCopy = `${url}`;
    const tempInput = document.createElement("input");
    document.body.appendChild(tempInput);
    tempInput.value = textToCopy;
    tempInput.select();
    document.execCommand("copy");
    document.body.removeChild(tempInput);
    setCopied(true);
    toast.success("Linked is copied");
  };

  const messageLink = `Hi, see this unit listed from Alliance Realtors! 😊 This beautiful unit is now on the market and available for rent!
  📷 View Photos: ${photourl}
  🔗 Unit Link: ${url}`;

  const whatsappLink = `https://web.whatsapp.com/send?text=${encodeURIComponent(messageLink)}`;


  const facebookLink = `https://www.facebook.com/sharer/sharer.php?u=${encodeURIComponent(messageLink)}`;

  const EmailLink = `mailto:recipient@example.com?subject=Your%20Subject&body=${messageLink}`;

  const twitterLink = `https://twitter.com/intent/tweet?text=${encodeURIComponent(messageLink)}`;

  const instagramLink = "https://www.instagram.com/direct/inbox/";

  function shareFacebook() {
    let url = encodeURIComponent(messageLink);
    let title = "title";
    window.open(
      `http://www.facebook.com/sharer.php?u=${url}`,
      "pop up name",
      "width=600, height=600"
    );
  }

  const accessToken = localStorage.getItem("authTokens");
  const [userCurrent, setUserId] = useState('')

  const toggleHearts = async (houseId) => {
    const prevHeartStateValue = heartState[houseId];

    // Update the UI to immediately reflect the change.
    setHeartState((prevHeartState) => ({
      ...prevHeartState,
      [houseId]: !prevHeartState[houseId],
    }));

    if (accessToken) {
      // Check if the user is logged in.
      const parsedTokens = JSON.parse(accessToken);
      const access = parsedTokens.access;

      // Headers access token.
      const config = {
        headers: { Authorization: `Bearer ${access}` }
      };

      // Decoding the token to get the user id.
      const decodedToken = jwtDecode(accessToken);
      const userId = decodedToken.user_id;

      // If the user has already liked the apartment, delete it.
      if (prevHeartStateValue) {
        try {
          const res = await serverUrl.get(`/landlord/viewers-favourites/?house=${houseId}&user=${userId}`, config);
          if (res.data.count === 1) {
            // If the user has liked the apartment, delete it.
            await serverUrl.delete(`/landlord/viewers-favourites/${res.data.results[0].id}`, config);
          }
        } catch (error) {
          console.log(error);
        }
      } else {
        // If the user hasn't liked the apartment, add it.
        const likedApartment = {
          house: houseId,
          user: userId,
          user_name:currentUser.full_name,

        };
        try {
          await serverUrl.post(`/landlord/viewers-favourites/`, likedApartment, config);
        } catch (error) {
          console.log(error);
        }
      }
    } else {
      toast.error('Kindly Login so that you can be able to like a house');
    }
  };

  function toCamelCase(str) {
    return str.replace(/_/g, " ");
  }

  // the data obtained
  const [formData, setFormData] = useState({
    name: '',
    email: '',
    phone: '',
    user_message: '',
  });
  // 

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  const [formDataBook, setFormDataBook] = useState({
    full_name: '',
    phone_number: '',
    email: '',
    starting_date: '', 
    starting_time: '', 
    duration:'',

  });
  // 
  useEffect(() => {
    if (currentUser) {
      setFormDataBook({
        full_name: currentUser.full_name || '',
        phone_number: currentUser.phone_number || '',
        email: currentUser.email || '',
        starting_date: '', 
        starting_time: '', 
        duration: '', 
      });
    }
  }, [currentUser]);
  

  const handleChangeBook = (e) => {
    const { name, value } = e.target;
  
    if (name === 'duration' && (isNaN(value) || parseInt(value) > 60)) {
      // Display an error message or handle it in another way
      toast.error('Invalid duration time. Please enter a value less than or equal to 60 minutes.');
      return;
    }
  
    setFormDataBook((prevData) => ({
      ...prevData,
      [name]: value,
    }));
  };



  function handleSimilar(e) {
    e.preventDefault();


    if (accessToken) {

      var EmailUser = false
      if (document.getElementById("emailUser").checked) {
        var EmailUser = true
      }
      if (EmailUser === true) {

        const data = {
          user: currentUser.id,
          house: houseSelected.id,
          county: houseSelected.county,
          location: houseSelected.location,
          min_rent: houseSelected.rent,
          max_rent: (houseSelected.rent + 5000),
          house_type: houseSelected.unit_type,
          user_name: currentUser.full_name,
          user_email: currentUser.email,
          user_phone: currentUser.phone_number,
          user_message: formData.user_message,
        }

        serverUrl.post(`/landlord/similar-products-unauthorized/`, data)
          .then((res) => {
            toast.success('Thank you for the confirmation we will be in touch soon')
            handleCloseModalImageCarousel()
            setFormData({
              house_number: "",
              total_rooms: "",
              due_date: "",
              bedroom: "",
              deposit: "",
              bathroom: "",
              rent: "",
              type_of_house: "",
            })
          })
          .catch((error) => {
            console.log(error)
          })
      }
      else {
        toast.error('Kindly confirm that you want to be contacted')

      }
    }



    else {
      var EmailUser = false
      if (document.getElementById("emailUser").checked) {
        var EmailUser = true
      }
      if (EmailUser === true) {

        const data = {
          user: '',
          house: houseSelected.id,
          county: houseSelected.county,
          location: houseSelected.location,
          min_rent: houseSelected.rent,
          max_rent: (houseSelected.rent + 5000),
          house_type: houseSelected.unit_type,
          user_name: formData.name,
          user_email: formData.email,
          user_phone: formData.phone,
          user_message: formData.user_message
        }

        serverUrl.post(`/landlord/similar-products-unauthorized/`, data)
          .then((res) => {
            toast.success('Thank you for the confirmation we will be in touch soon')
            handleCloseModalImageCarousel()
            setFormData({
              house_number: "",
              total_rooms: "",
              due_date: "",
              bedroom: "",
              deposit: "",
              bathroom: "",
              rent: "",
              type_of_house: "",
            })
          })
          .catch((error) => {
            console.log(error)
          })
      }
      else {
        toast.error('Kindly confirm that you want to be contacted')

      }


    }


  }

  function handleBooking(e) {
    e.preventDefault();
    if (accessToken) {
      // check first if user is logged in
      const parsedTokens = JSON.parse(accessToken);
      const access = parsedTokens.access;
      // headers access token
      const config = {
        headers: { Authorization: `Bearer ${access}` },
      };
      // decoding the token so that i can get the user id
      const decodedToken = jwtDecode(accessToken);
      const userId = decodedToken.user_id;

 
      const data = {
        user: userId,
        user_name: formDataBook.full_name,
        user_phone: formDataBook.phone_number,
        user_email: formDataBook.email,
        meeting_date: formDataBook.starting_date,
        house: id,
        apartment: houseSelected.apartment_id,
        apartment_name: houseSelected.apartment,
        house_name: houseSelected.name,
        starting_time: (() => {
          if (formDataBook.starting_time) {
            const startTime = new Date(`2023-11-28 ${formDataBook.starting_time}`);
      
            const formatter = new Intl.DateTimeFormat('en-US', {
              hour: 'numeric',
              minute: 'numeric',
              second: 'numeric',
              millisecond: 'numeric',
              hour12: false,
            });
      
            return formatter.format(startTime);
          }
          return null;
        })(),
        duration: formDataBook.duration,
        end_time: (() => {
          if (formDataBook.starting_time && formDataBook.duration) {
            const startTime = new Date(`2023-11-28 ${formDataBook.starting_time}`);
            const durationMinutes = parseInt(formDataBook.duration, 10);
      
            if (!isNaN(durationMinutes)) {
              const endTime = new Date(startTime.getTime() + durationMinutes * 60000);
      
              const formatter = new Intl.DateTimeFormat('en-US', {
                hour: 'numeric',
                minute: 'numeric',
                second: 'numeric',
                millisecond: 'numeric',
                hour12: false,
              });
      
              return formatter.format(endTime);
            }
          }
          return null;
        })(),
      };
      

      
      
      serverUrl.post(`/landlord/booking/`, data, config)
      .then((res)=>{
        toast.success('Succesfully booked a viewing appointment our team will reach out to you for more details')
        setBookModal(false)

      })
      .catch((error)=>{
        if(error.response.status === 400){
          toast.error(error.response.data.error)
        }
        else{
          console.log(error)

        }
      })
    }

  }

  const handlePlatform = (platform) => {
    var Platform = platform
    if (accessToken) {

      // Check if the user is logged in.
      const parsedTokens = JSON.parse(accessToken);
      const access = parsedTokens.access;

      // Headers access token.
      const config = {
        headers: { Authorization: `Bearer ${access}` },
      };

      // Decoding the token to get the user id.
      const decodedToken = jwtDecode(accessToken);
      const userId = decodedToken.user_id;

      const data = {
        user: userId,
        house: id,
        platform: Platform,
      }

      serverUrl.post(`/landlord/shared-units/`, data, config)
        .then((res) => {
          console.log(res.data.results)
        })
        .catch((error) => {
          console.log(error)
        })
      if (platform === 'whatsapp') {
        window.open(whatsappLink, '_blank');
      }
      else if (platform === 'facebook') {
        // Handle Facebook logic here
        // ...
      }
      else if (platform === 'gmail') {
        window.open(EmailLink)
      }
      else if (platform === 'x') {
        window.open(twitterLink, '_blank')
      }
      else if (platform === 'instagram') {
        window.open(instagramLink, '_blank')
      }
      else if (platform === 'copy_link') {
        handleCopyLink()
      }
    }
    else {
      toast.error('Kindly Login so that you can be able to share this amazing unit')
    }

  };

  const [bookModal, setBookModal] = useState(false)

  const handleShowBook = () => {
    if (accessToken) {
      setBookModal(true)

    }
    else {
      toast.error('Kindly login so that you can be able to book a viewing')
    }
  }
  const handleCloseBook = () => {
    setBookModal(false)
  }
  return (
    <>
      <NavigationBar />


      <div className="selectedHouseImageContent">

        {/* top view details */}
        <div className="topviewDesktopHouseDetails">
          <h3>
            {houseSelected.name}
          </h3>
          <div className="topselectedHousereviews">
            <div className="reviewsLocation">
              <Link className="text-dark" style={{ fontSize: "0.9rem" }}>
                {houseSelected.location},{houseSelected.county}
              </Link>
            </div>
            <div className="shareSave">
              <Link
                className="text-dark"
                style={{ fontSize: "0.9rem", marginRight: "1.5rem" }}
                onClick={handleShow}
              >
                <i class="bi bi-upload"></i> Share
              </Link>

              <Modal size="lg" show={show} onHide={handleClose}>
                <Modal.Header closeButton>
                  <Modal.Title>Share this unit details</Modal.Title>
                </Modal.Header>
                <Modal.Body>
                  <Row className="d-flex">
                    <div
                      className="text-decoration-none text-dark w-50"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => handlePlatform('whatsapp')}
                    >
                      <Col
                        md={6}
                        className="d-flex"
                        style={{
                          border: "1px solid lightgray",
                          width: "91%",
                          marginInline: "0.5rem",
                          marginTop: "1rem",
                          borderRadius: "10px",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.backgroundColor = "lightgray")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundColor = "inherit")
                        }
                      >
                        <Image
                          src={Whatsapp}
                          className="mb-2 me-2 mt-2 ms-2"
                          style={{
                            borderRadius: "10px",
                            width: "auto",
                            height: "5vh",
                          }}
                        />
                        <p className="mt-3">Share on WhatsApp</p>
                      </Col>
                    </div>

                    <div
                      className="text-decoration-none text-dark w-50"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => handlePlatform('facebook')}

                    >
                      <Col
                        md={6}
                        className="d-flex"
                        style={{
                          border: "1px solid lightgray",
                          width: "91%",
                          marginInline: "0.5rem",
                          marginTop: "1rem",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.backgroundColor = "lightgray")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundColor = "inherit")
                        }
                        onClick={() => shareFacebook()}
                      >
                        <Image
                          src={Facebook}
                          className="mb-2 me-2 mt-2 ms-2"
                          style={{
                            borderRadius: "10px",
                            width: "auto",
                            height: "5vh",
                          }}
                        />
                        <p className="mt-3">Share on Facebook</p>
                      </Col>
                    </div>
                  </Row>

                  <Row className="d-flex">
                    <div
                      className="text-decoration-none text-dark w-50"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => handlePlatform('gmail')}
                    >
                      <Col
                        md={6}
                        className="d-flex"
                        style={{
                          border: "1px solid lightgray",
                          width: "91%",
                          marginInline: "0.5rem",
                          marginTop: "1rem",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.backgroundColor = "lightgray")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundColor = "inherit")
                        }
                      >
                        <Image
                          src={Mail}
                          className="mb-2 me-2 mt-2 ms-2"
                          style={{
                            borderRadius: "10px",
                            width: "auto",
                            height: "5vh",
                          }}
                        />
                        <p className="mt-3">Share on Gmail</p>
                      </Col>
                    </div>

                    <div
                      className="text-decoration-none text-dark w-50"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => handlePlatform('x')}
                    >
                      <Col
                        md={6}
                        className="d-flex"
                        style={{
                          border: "1px solid lightgray",
                          width: "91%",
                          marginInline: "0.5rem",
                          marginTop: "1rem",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.backgroundColor = "lightgray")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundColor = "inherit")
                        }
                      >
                        <Image
                          src={Twitter}
                          className="mb-2 me-2 mt-2 ms-2"
                          style={{
                            borderRadius: "10px",
                            width: "auto",
                            height: "5vh",
                          }}
                        />
                        <p className="mt-3">Share on Twitter</p>
                      </Col>
                    </div>
                  </Row>

                  <Row className="d-flex">

                    <div
                      className="text-decoration-none text-dark w-50"
                      style={{
                        cursor: "pointer",
                      }}
                      onClick={() => handlePlatform('instagram')}
                    >
                      <Col
                        md={6}
                        className="d-flex"
                        style={{
                          border: "1px solid lightgray",
                          width: "91%",
                          marginInline: "0.5rem",
                          marginTop: "1rem",
                          borderRadius: "10px",
                          cursor: "pointer",
                        }}
                        onMouseEnter={(e) =>
                          (e.currentTarget.style.backgroundColor = "lightgray")
                        }
                        onMouseLeave={(e) =>
                          (e.currentTarget.style.backgroundColor = "inherit")
                        }
                      >
                        <Image
                          src={Instagram}
                          className="mb-2 me-2 mt-2 ms-2"
                          style={{
                            borderRadius: "10px",
                            width: "auto",
                            height: "5vh",
                          }}
                        />
                        <p className="mt-3">Share on Instagram</p>
                      </Col>
                    </div>

                    <Col
                      md={6}
                      style={{
                        border: "1px solid lightgray",
                        width: "44%",
                        marginInline: "1.1rem",
                        marginTop: "1rem",
                        borderRadius: "10px",
                        cursor: "pointer",
                      }}
                      onMouseEnter={(e) =>
                        (e.currentTarget.style.backgroundColor = "lightgray")
                      }
                      onMouseLeave={(e) =>
                        (e.currentTarget.style.backgroundColor = "inherit")
                      }
                      onClick={() => handlePlatform('copy_link')}
                    >
                      <div className="d-flex">
                        <Image src={Paperclip}
                          className="mb-2 me-2 mt-2"
                          style={{
                            borderRadius: "10px",
                            width: "auto",
                            height: "5vh",
                          }}
                        />
                        <p className="mt-3">Copy the link </p>
                      </div>
                    </Col>
                  </Row>
                </Modal.Body>
                <Modal.Footer>
                  <Button variant="secondary" onClick={handleClose}>
                    Close
                  </Button>
                </Modal.Footer>
              </Modal>

              <Link
                className="text-dark"
                onClick={() => toggleHearts(id)}
                style={{ fontSize: "0.9rem" }}
              >
                {heartState[id] ? (
                  <i class="bi bi-heart-fill text-warning"></i>

                ) : (
                  <i class="bi bi-heart"></i>

                )}
                Save
              </Link>
            </div>
          </div>
        </div>

        {/* desktop view images */}

        {houseSelectedImages.length > 0 && (
          <Row className="imageSelectedHouseRow">
            <Col
              md={6}
              className="topImageleft"
              onClick={() => handleImageClickImageCarousel(0)}
            >
              <Image src={houseSelectedImages[0].image} />
            </Col>

            <Col md={6}>
              <Row className="topImageright">
                <Col
                  md={6}
                  className="topImagerightChild"
                  onClick={() => handleImageClickImageCarousel(1)}
                >
                  <Image src={houseSelectedImages[1].image} />
                </Col>
                <Col
                  md={6}
                  className="topImagerightChild"
                  onClick={() => handleImageClickImageCarousel(2)}
                >
                  <Image
                    src={houseSelectedImages[2].image}
                    style={{ borderRadius: "0px 20px 0px 0px" }}
                  />
                </Col>
              </Row>
              <Row className="bottomImageright">
                <Col
                  md={6}
                  className="bottomImagerightChild"
                  onClick={() => handleImageClickImageCarousel(3)}
                >
                  <Image src={houseSelectedImages[3].image} />
                </Col>
                <Col
                  md={6}
                  className="bottomImagerightChild"
                  onClick={() => handleImageClickImageCarousel(4)}
                >
                  <Image
                    src={houseSelectedImages[4].image}
                    style={{ borderRadius: "0px 0px 20px 0px" }}
                  />
                </Col>
                <div className="d-flex" style={{ justifyContent: "right" }}>
                  <Button
                    className="btn btn-warning btn-sm w-10 text-white"
                    style={{ width: "25%", height: "4vh", marginTop: "-2rem" }}
                  >
                    <Image src={Grid} style={{ width: "9%", marginLeft: "0.5rem" }} />
                    <Link
                      className="text-decoration-none text-white ms-2"
                      to={`/unit/${id}`}
                    >
                      Show all photos
                    </Link>
                  </Button>
                </div>
              </Row>
            </Col>
          </Row>
        )}

        {/* Modal for displaying selected image and carousel */}
        <Modal
          show={showModalImageCarousel}
          onHide={handleCloseModalImageCarousel}
          centered
          size="xl"
          className="imageCarouselModalHouse"
        >
          <Modal.Header closeButton>
            <h2 className="text-uppercase"> {houseSelected.name} Images</h2>

          </Modal.Header>
          <Row>
            <Col md={8}>
              <Modal.Body>
                <Carousel
                  activeIndex={selectedImageIndexCarousel}
                  onSelect={handleImageSelectImageCarousel}
                >
                  {houseSelectedImages.map((image, index) => (
                    <Carousel.Item key={index}>
                      <Image
                        src={image.image}
                        fluid
                        className="topCarouselselectedHouse"
                      />
                    </Carousel.Item>
                  ))}
                </Carousel>
              </Modal.Body>
              <Modal.Footer>
                <div style={{ overflowX: "auto", whiteSpace: "nowrap" }}>
                  {houseSelectedImages.map((image, index) => (
                    <Image
                      key={index}
                      src={image.image}
                      onClick={() => handleImageSelectImageCarousel(index)}
                      style={{
                        cursor: "pointer",
                        marginRight: "10px",
                        border:
                          index === selectedImageIndexCarousel
                            ? "2px solid #dee2e6"
                            : "none",
                      }}
                      fluid
                    />
                  ))}
                </div>
              </Modal.Footer>
            </Col>

            <Col md={4}>
              <Modal.Body>
                <Card className="border-0">
                  {accessToken ?
                    <>
                      <p>Confirm if the details below are okay.</p>
                      <Form onSubmit={handleSimilar}>
                        <FormControl type='text' placeholder="Full Name" name='name' className="mt-3" value={currentUser.full_name} onChange={handleChange} required />
                        <FormControl type='phone' placeholder="Phone Number" name='phone' className="mt-3" value={currentUser.phone_number} onChange={handleChange} required />
                        <FormControl type='email' placeholder="Your Email" className="mt-3" name='email' value={currentUser.email} onChange={handleChange} required />

                        <textarea rows={4} className="mt-3 textareaSimilar" name="user_message" value={formData.user_message} onChange={handleChange}>
                          Hi, I'd like to know more about this unit. Please get in touch with me.
                        </textarea>
                        <div className='d-flex'>
                          <input type="checkbox" id='emailUser' style={{ height: "15px", width: "10px", marginTop: "0.2rem", marginRight: "0.5rem" }} /><span>Contact me about similar units</span>

                        </div>

                        <Button className='btn btn-sm btn-warning text-white mt-3' type='submit'>Save Information</Button>
                      </Form>


                    </>
                    :
                    <>
                      <p>Kindly fill in the form below.</p>

                      <Form onSubmit={handleSimilar}>
                        <FormControl type='text' placeholder="Full Name" name='name' className="mt-3" value={formData.name} onChange={handleChange} required />
                        <FormControl type='number' placeholder="Phone Number" name='phone' className="mt-3" value={formData.phone} onChange={handleChange} required />
                        <FormControl type='email' placeholder="Your Email" className="mt-3" name='email' value={formData.email} onChange={handleChange} required />

                        <textarea rows={4} className="mt-3 textareaSimilar" name="user_message" value={formData.user_message} onChange={handleChange}>
                          Hi, I'd like to know more about this unit. Please get in touch with me.
                        </textarea>
                        <div className='d-flex'>
                          <input type="checkbox" id='emailUser' style={{ height: "15px", width: "10px", marginTop: "0.2rem", marginRight: "0.5rem" }} /><span>Contact me about similar units</span>

                        </div>

                        <Button className='btn btn-sm btn-warning text-white mt-3' type='submit'>Save Information</Button>
                      </Form>
                    </>



                  }

                </Card>
              </Modal.Body>
            </Col>
          </Row>
        </Modal>

        {/* mobile view images carousle */}
        <Slider {...settingsImageMobile} className="mobileviewImages">
          {houseSelectedImages.map((imageUrl) => (
            <div key={imageUrl} className="">
              <img src={imageUrl.image} />
            </div>
          ))}
        </Slider>

        <div className="topviewMobileHouseDetails mt-3">
          <h3>
            {houseSelected.name}
          </h3>
          <div className="topselectedHousereviews d-flex justify-content-between" style={{width:"150%"}}>
            <div className="reviewsLocation">
              <Link className="text-dark" style={{ fontSize: "0.9rem" }}>
                {houseSelected.location},{houseSelected.county}
              </Link>
            </div>
            <div className="shareSave">
              <Link
                className="text-dark"
                style={{ fontSize: "0.9rem", marginRight: "1.5rem" }}
                onClick={handleShow}

              >
                <i class="bi bi-upload"></i> Share
              </Link>
              <Link
                className="text-dark"
                onClick={() => toggleHearts(id)}
                style={{ fontSize: "0.9rem" }}
              >
                {heartState[id] ? (
                  <i class="bi bi-heart-fill text-warning"></i>

                ) : (
                  <i class="bi bi-heart"></i>

                )}
                Save
              </Link>
            </div>
          </div>
        </div>

        <Row className="mt-3" style={{marginInline:"0rem"}}>
          <Col md={6} sm={12} xm={12}>
            <div
              className="d-flex justify-content-between"
              style={{ borderBottom: "1px solid lightgray" }}
            >
              <div>
                {houseSelected.unit_type && (
                  <h5 class="text-capitalize">
                    {toCamelCase(houseSelected.unit_type)}
                  </h5>
                )}
                <div
                  className="d-flex border-bottom-1 mb-2 text-secondary"
                  style={{ fontSize: "0.9rem" }}
                >
                  <p className="mb-1 me-2">
                    {houseSelected.bedrooms} bedrooms{" "}
                  </p>
                  <p
                    className="mb-1"
                    style={{ marginTop: "-0.8rem", fontSize: "1.5rem" }}
                  >
                    {" "}
                    .{" "}
                  </p>
                  <p className="mb-1 ms-2">
                    {" "}
                    {houseSelected.bathroom} bathrooms
                  </p>
                </div>
              </div>
              <Image src={User} style={{ width: "8%", height: "64%" }} />
            </div>
            {unitAmenities.length > 0 ? (
              <div style={{ borderBottom: "1px solid lightgray" }}>
                {unitAmenities.map((amenities) => (
                  <div className="mt-2 d-flex p-2">
                    <Image
                      src={amenities.icon_image}
                      style={{ width: "15%", height: "10vh" }}
                    />
                    <div className="ms-4">
                      <h6>{amenities.icon}</h6>
                      <p style={{ fontSize: "0.8rem" }} className="text-muted">
                        {amenities.icon_description}
                      </p>
                    </div>
                  </div>
                ))}
              </div>
            ) : (
              <></>
            )}
          </Col>

          <Col md={6} sm={12} xm={12}>
            <Card
              className="w-75 ms-auto webviewBooking"
              style={{ boxShadow: "6px 2px 12px lightgray" }}
            >
              <Card.Body>
                <div className="d-flex justify-content-between">
                  {houseSelected.rent && (

                    houseSelected.type_rent_status === 'for_sale' ?
                      <span style={{ fontSize: "0.7rem" }}>
                        <b className="fs-6">
                          Ksh.{houseSelected.rent.toLocaleString()}
                        </b>{" "}
                      </span>
                      :

                      <span style={{ fontSize: "0.7rem" }}>
                        <b className="fs-6">
                          Ksh.{houseSelected.rent.toLocaleString()}
                        </b>{" "}
                        /month
                      </span>
                  )}

                </div>
                <Row style={{ padding: "0.8rem" }}>
                  <Col
                    md={6}
                    className="dateColDisplay"
                    style={{ borderRadius: "10px 0px 0px 0px" }}
                  >
                    <h6 style={{ fontSize: "0.8rem" }}>Date Posted</h6>
                    <p style={{ fontSize: "0.7rem " }}>
                      {" "}
                      {new Date(houseSelected.created_at).toLocaleDateString(
                        "en-US",
                        {
                          month: "long",
                          day: "numeric",
                          year: "numeric",
                        }
                      )}
                    </p>
                  </Col>
                  <Col
                    md={6}
                    className="dateColDisplay"
                    style={{ borderRadius: "0px 10px 0px 0px" }}
                  >
                    <h6 style={{ fontSize: "0.8rem" }}>Type</h6>

                    {houseSelected && (
                      <p
                        className="text-uppercase"
                        style={{ fontSize: "0.7rem " }}
                      >
                        {houseSelected.rent_type} - {houseSelected.type_rent_status}
                      </p>

                    )}
                  </Col>
                  {/* <Col md={6} className="dateColDisplayLast">
                    <h6 style={{ fontSize: "0.8rem" }}>Past Tenants</h6>
                    <p style={{ fontSize: "0.7rem " }}>
                      {houseSelected.tenants_count} Tenants
                    </p>
                  </Col> */}
                  <Col md={12} className="dateColDisplaySecondLast">
                    <h6 style={{ fontSize: "0.8rem" }}>Unit Size</h6>
                    <p style={{ fontSize: "0.7rem " }}>
                      {houseSelected.size} Sq ft
                    </p>
                  </Col>
                </Row>

                <Button
                  className="btn mt-2  btn-warning text-white d-flex m-auto w-100 justify-content-center"
                  style={{ height: "3.2rem", paddingTop: "0.8rem" }}
                  onClick={handleShowBook}
                >
                  Contact us
                </Button>
              </Card.Body>
            </Card>

            <Modal
              show={bookModal}
              onHide={handleCloseBook}
              centered
              dialogClassName="modal-100w"
            >
              <Modal.Header closeButton>Contact us</Modal.Header>
              <Modal.Body>
                <Form onSubmit={handleBooking}>
                  <FormControl type='text' placeholder="Full Name" name='full_name' className="mt-3" value={formDataBook.full_name} onChange={handleChangeBook} required />
                  <FormControl type='phone' placeholder="Phone Number" name='phone_number' className="mt-3" value={formDataBook.phone_number} onChange={handleChangeBook} required />
                  <FormControl type='email' placeholder="Your Email" className="mt-3" name='email' value={formDataBook.email} onChange={handleChangeBook} required />
                  <label className="mt-2">Date of viewing</label>
                  <FormControl type='date' className="mt-3" name='starting_date' value={formDataBook.starting_date} onChange={handleChangeBook} required />
                  <label className="mt-2">Start Time</label>
                  <FormControl type='time' className="mt-3" name='starting_time' value={formDataBook.starting_time} onChange={handleChangeBook} required />
                  <label className="mt-2">Duration</label>
                  <FormControl type='number' className="mt-3" name='duration' value={formDataBook.duration} onChange={handleChangeBook} required />
                  <Button className='btn btn-sm btn-warning text-white mt-3' type='submit'>Save Booking</Button>
                </Form>
              </Modal.Body>
              <Modal.Footer>
                <Button variant="secondary" onClick={handleCloseBook}>
                  Close
                </Button>
              </Modal.Footer>
            </Modal>

            <Card className="mobileviewBooking">
              <Card.Body>
                {houseSelected.rent && (

                  houseSelected.type_rent_status === 'for_sale' ?
                    <span style={{ fontSize: "0.7rem" }}>
                      <b className="fs-6">
                        Ksh.{houseSelected.rent.toLocaleString()}
                      </b>{" "}
                    </span>
                    :

                    <span style={{ fontSize: "0.7rem" }}>
                      <b className="fs-6">
                        Ksh.{houseSelected.rent.toLocaleString()}
                      </b>{" "}
                      /month
                    </span>
                )}
                <Button className="btn btn-sm  btn-warning text-white">
                  Contact us
                </Button>
              </Card.Body>
            </Card>
          </Col>
        </Row>

        <div className="descriptionSelectedHouse">
          <div className="p-3">
            <h5 className="float-left">Description</h5>
            {houseSelected.about === null ? (
              <p className="descriptionHouseSelected">
                We take pride in offering the finest houses and living spaces,
                and our commitment is unwavering.
              </p>
            ) : (
              <p className="descriptionHouseSelected">{houseSelected.about}</p>
            )}
          </div>
          <p
            style={{
              borderBottom: "1px solid lightgray",
              width: "50%",
              textAlign: "left",
              marginBottom: "0",
            }}
          ></p>
        </div>

        {/* {roomImages.length > 0 ? (
          <div className="roomImages">
            <div className="" style={{ padding: "1.2rem" }}>
              <h5>Room Images</h5>

              <Slider {...settingsSpecial} className="imageDemo p-0">
                {roomImages.map((imageUrl, index) => (
                  <div key={imageUrl.id} className="selectedHouseCarousel ">
                    <img src={imageUrl.room_image} alt={imageUrl.room} />
                    <p className="mt-2">{imageUrl.room}</p>
                  </div>
                ))}
              </Slider>
            </div>

            <p
              className="descriptionHouseSelected"
              style={{
                borderBottom: "1px solid lightgray",
                width: "50%",
                textAlign: "left",
              }}
            ></p>
          </div>
        ) : (
          <></>
        )} */}

        {/* <div>
          {houseSelected.amenities_count === 0 ? (
            <></>
          ) : (
            <Row className="p-2">
              <Col md={6}>
                <h5>What this unit offers</h5>
                <Row>
                  {houseSelectedAmenities.slice(0, 4).map((essential) => (
                    <Col md={6} className="d-flex">
                      <Image
                        src={essential.icon}
                        style={{
                          width: "10%",
                          height: "65%",
                          marginTop: "0.9rem",
                          marginLeft: "0.7rem",
                        }}
                      />
                      <label
                        style={{ marginTop: "0.8rem", marginLeft: "1.5rem" }}
                      >
                        {essential.name}
                      </label>
                    </Col>
                  ))}
                </Row>
                <Button
                  className="btn btn-outline-warning bg-transparent text-dark w-auto mb-3 mt-3 d-flex"
                  onClick={handleAmenitiesOpen}
                >
                  Show all {houseSelected.amenities_count} amenities
                </Button>
                <Modal
                  show={showModal}
                  onHide={handleAmenitiesClose}
                  centered
                  dialogClassName="modal-100w"
                >
                  <Modal.Header closeButton></Modal.Header>
                  <Modal.Body>
                    <Row>
                      {houseSelectedAmenities.map((essential) => (
                        <Col md={6} className="d-flex">
                          <Image
                            src={essential.icon}
                            style={{
                              width: "10%",
                              height: "65%",
                              marginTop: "0.9rem",
                              marginLeft: "0.7rem",
                            }}
                          />
                          <label
                            style={{
                              marginTop: "0.8rem",
                              marginLeft: "1.5rem",
                            }}
                          >
                            {essential.name}
                          </label>
                        </Col>
                      ))}
                    </Row>
                  </Modal.Body>
                  <Modal.Footer>
                    <Button variant="secondary" onClick={handleAmenitiesClose}>
                      Close
                    </Button>
                  </Modal.Footer>
                </Modal>
              </Col>
            </Row>
          )}
        </div> */}

        {/* <div
          className="locationMap"
          style={{ borderBottom: "1px solid lightgray", marginTop: "1.5rem" }}
        >
          <h5 className="mt-2" style={{ marginBottom: "1.5rem" }}>
            Where you will be
          </h5>

          <MapComponent />
          <p></p>
        </div> */}

        {/* <div>
          {nearbyFacilities.length > 0 ? (
            <Row
              className="mt-2"
              style={{ borderBottom: "1px solid lightgray" }}
            >
              <Col md={6} className="p-3">
                <h5>Nearby Facilities</h5>
                <Row>
                  {nearbyFacilities.map((essential) => (
                    <Col md={6} className="d-flex">
                      <Image
                        src={essential.icon_image}
                        style={{
                          width: "10%",
                          height: "55%",
                          marginTop: "0.9rem",
                          marginLeft: "0.7rem",
                        }}
                      />
                      <label
                        style={{ marginTop: "0.8rem", marginLeft: "1.5rem" }}
                      >
                        <span>{essential.icon}</span> is {essential.distance}m{" "}
                        away
                      </label>
                    </Col>
                  ))}
                </Row>
              </Col>
            </Row>
          ) : (
            <></>
          )}
        </div> */}

        {/* <div>
                    <Row className='mt-2' style={{ borderBottom: "1px solid lightgray" }}>
                        <Col md={6} className='p-3'>
                            <h5>Agent Management</h5>

                            <div className='d-flex mb-2'>
                                <Image src={Person} style={{ width: "9%", borderRadius: "50%" }} />
                                <p style={{ fontSize: "0.8rem", marginLeft: "0.5rem", marginTop: "1rem" }}>Jirani Management Sacco</p>
                            </div>
                            <p style={{ fontSize: "0.8rem" }}>We have managed properties since 1994 and currently we are managing 300 properties.</p>
                            <Link to='#' style={{ fontSize: "0.8rem" }}>www.jiranisaccomanagement.com</Link>

                        </Col>

                    </Row>

                </div> */}


        {/* <div>
          <Row className="mt-3" style={{ borderBottom: "1px solid lightgray" }}>
            {rules === 0 ? (
              <></>
            ) : (
              <>
                <h5 style={{ marginBottom: "1rem", marginTop: "1rem" }}>
                  Things To Know
                </h5>
                {rulesProperty && (
                  <Col md={4} style={{ fontSize: "1.0rem" }}>
                    <h6>Property Rules</h6>
                    <ul className="text-secondary">
                      {rulesProperty.map((property) => (
                        <li>{property.description}</li>
                      ))}
                    </ul>
                  </Col>
                )}

                {rulesSafety && (
                  <Col md={4} style={{ fontSize: "1.0rem" }}>
                    <h6>Safety</h6>
                    <ul className="text-secondary">
                      {rulesSafety.map((property) => (
                        <li>{property.description}</li>
                      ))}
                    </ul>
                  </Col>
                )}

                {rulesRental && (
                  <Col md={4} style={{ fontSize: "1.0rem" }}>
                    <h6>Rental Policy</h6>
                    <ul className="text-secondary">
                      {rulesRental.map((property) => (
                        <li>{property.description}</li>
                      ))}
                    </ul>
                  </Col>
                )}
              </>
            )}
          </Row>
        </div> */}
      </div>
      <p></p>
    </>
  );
}

export default SelectedPropertAlliance;
