import React, { useState, useEffect } from 'react'
import '../assets/scss/ListingPage.scss'
import { Button, Card, Image } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { Link, useParams } from 'react-router-dom'
import Footer from '../components/Footer';
import NavigationBar from '../components/NavigationBar';
import Logo from '../assets/images/logo_alliance.png'
import TopMenuFilter from '../components/TopMenuFilter';
import Heart from '../assets/images/heart__1_.png'
import Heart2 from '../assets/images/heart.png'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/scss/TopMenuFilter.scss'
import Map from '../assets/images/map.png'
import { serverUrl } from '../serverUrl';
import { jwtDecode } from 'jwt-decode';
import { toast } from "react-toastify";

function ListingPage(props) {
    const { id } = useParams();
    // loading status
    const location = useLocation();
    const [isLoading, setIsLoading] = useState(false)

    // get the logged in user and get their user id
    // after the login is succesful then get to add them in the viewers table 
    const accessToken = localStorage.getItem("authTokens");

    const [dataFromChild, setDataFromChild] = useState('');
    // Function to handle data from the child
    const handleChildData = (childData) => {
        setDataFromChild(childData);
    };
    const [dataFromSearch, setDataFromSearch] = useState('');
    // Function to handle data from the child
    const handleSearchData = (searchData) => {
        setDataFromSearch(searchData);
    };

    useEffect(() => {
        // Scroll to the top of the page when the route changes
        window.scrollTo(0, 0);
    }, [location]);

    const [landlordApartment, setLandlordApartment] = useState([])
    const [currentUser, setCurrentUser] = useState([])
    const [countySelected, setCountySelected] = useState('');

    // get the searched data
    useEffect(() => {
        setIsLoading(true)
        if (id === 'All') {
            const saved = localStorage.getItem("searchResults");
            const countySelected = localStorage.getItem("countySelected");
            setCountySelected(countySelected)
            const initialValue = JSON.parse(saved)
            setLandlordApartment(initialValue)
            setIsLoading(false)

        }
        else if (id === 'Featured') {
            serverUrl.get(`/landlord/all-landlords/`)
                .then((res) => {
                    setIsLoading(false)

                    setLandlordApartment(res.data.results.filter((apartment => apartment.is_featured === true)))
                })
                .catch((error) => {
                    console.log(error)
                })
        }
        else {
            serverUrl.get(`/landlord/alliance-county-properties?county_name=${id}`)
                .then((res) => {
                    setLandlordApartment(res.data.results)
                    setIsLoading(false)

                })
                .catch((error) => {
                    console.log(error)
                })
        }

        if (accessToken) {
            // check first if user is logged in
            const parsedTokens = JSON.parse(accessToken);
            const access = parsedTokens.access;
            // headers access token
            const config = {
                headers: { Authorization: `Bearer ${access}` }
            }
            // decoding the token so that i can get the user id
            const decodedToken = jwtDecode(accessToken);
            const userId = decodedToken.user_id;
            // get the current user
            serverUrl.get(`/users/users-details/${userId}/`, config)
                .then((res => {
                    setCurrentUser(res.data)
                    setIsLoading(false)

                }))
                .catch((error) => {
                    console.log(error)
                })
            // Fetch liked units for the user based on their userId.
            serverUrl
                .get(`/landlord/viewers-favourites/?user=${userId}`, config)
                .then((res) => {
                    // Process the response to determine which units are liked by the user.
                    const likedApartment = res.data.results.map((likedApartment) => likedApartment.apartment);
                    // Update the heartState based on the likedUnits.
                    const newHeartState = { ...heartState };
                    likedApartment.forEach((apartmentId) => {
                        newHeartState[apartmentId] = true;
                    });
                    setHeartState(newHeartState);

                })
                .catch((error) => {
                    console.log(error);
                });
        }


    }, [])


    function containsSearchQuery(item, dataFromSearch) {
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(dataFromSearch.toLowerCase());
    }
    const filteredData = landlordApartment.filter((item) => {
        // Filter by selected property type if dataFromChild is not blank
        if (dataFromChild && item.type_of_apartment !== dataFromChild) {
            return false;
        }

        // Filter by searchQuery if it's not blank
        if (dataFromSearch && !containsSearchQuery(item, dataFromSearch)) {
            return false;
        }
        return true;
    });
    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 8;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);

    // randomise the images for my properties
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 10) + 1;
            while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);


    // randomise for each apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState({});
    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    useEffect(() => {
        const getRandomImages = () => {
            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
            const uniqueRandomImages = [];

            while (uniqueRandomImages.length < 5) {
                const randomNumber = Math.floor(Math.random() * availableImages.length);
                const randomImage = availableImages.splice(randomNumber, 1)[0];

                if (!usedImages.includes(randomImage)) {
                    uniqueRandomImages.push(randomImage);
                }
            }

            return uniqueRandomImages.map((imageNumber) =>
                `https://kodinyumba.app/media/Stock/Property/apartment${imageNumber}.jpg`
            );
        };

        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImageApartment };

            for (const unitDetail of landlordApartment) {
                const apartmentId = unitDetail.id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImages();
                }
            }
            setBackgroundImageApartment(updatedImages);
        };

        updateBackgroundImages();
    }, [landlordApartment, backgroundImageApartment]);

    const renderCarouselItems = (apartmentId) => {
        // const uniqueImages = backgroundImageApartment[apartmentId] || [];
        const uniqueImages = landlordApartment.filter((apartment) => apartment.id === apartmentId)
        const Images = uniqueImages[0].property_image

        return Images.map((imageUrl, index) => (
            <Slider {...settings}>

                <Image src={imageUrl.image} alt={`Image ${index + 1}`} fluid />

            </Slider>
        ));
    };


    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };
    const [heartState, setHeartState] = useState({});


    const toggleHearts = async (apartmentId) => {
        const prevHeartStateValue = heartState[apartmentId];

        // Update the UI to immediately reflect the change.
        setHeartState((prevHeartState) => ({
            ...prevHeartState,
            [apartmentId]: !prevHeartState[apartmentId],
        }));

        if (accessToken) {
            // Check if the user is logged in.
            const parsedTokens = JSON.parse(accessToken);
            const access = parsedTokens.access;

            // Headers access token.
            const config = {
                headers: { Authorization: `Bearer ${access}` }
            };

            // Decoding the token to get the user id.
            const decodedToken = jwtDecode(accessToken);
            const userId = decodedToken.user_id;

            // If the user has already liked the apartment, delete it.
            if (prevHeartStateValue) {
                try {
                    const res = await serverUrl.get(`/landlord/viewers-favourites/?apartment=${apartmentId}&user=${userId}`, config);
                    if (res.data.count === 1) {
                        // If the user has liked the apartment, delete it.
                        await serverUrl.delete(`/landlord/viewers-favourites/${res.data.results[0].id}`, config);
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                // If the user hasn't liked the apartment, add it.
                const likedApartment = {
                    apartment: apartmentId,
                    user: userId,
                    user_name: currentUser.full_name,
                };
                try {
                    await serverUrl.post(`/landlord/viewers-favourites/`, likedApartment, config);
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            toast.error('Kindly Login so that you can be able to like property');
        }
    };


    // if the data is loading show a message
    if (isLoading) {
        return (
            <div className="loader-container">
                <img src={Logo} alt="Loading..." />
            </div>
        )
    }


    return (

        <>
            <NavigationBar/>
            <TopMenuFilter onData={handleChildData} {...props} />

            <div className='property'>

                <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
                    {id === 'All' ?
                        <>
                            <p className='properties' style={{ padding: "2.5rem" }}>{id} PROPERTIES {countySelected ? <span> in {countySelected}</span> : <></>} </p>

                        </>
                        : id === 'Featured' ?
                            <>
                                <p className='properties'>{id} PROPERTIES</p>

                            </>
                            :
                            <>
                                <p className='properties'>PROPERTIES</p>

                                <span className='properties'>{id}</span>
                            </>

                    }


                </div>

                {/* the cards that have the houses and their properties */}
                <div className='housesCards'>
                    {!displayedItems && landlordApartment.length === 0 ?
                        <p className='notfoundp'>Sorry no property was found </p>
                        :
                        displayedItems.map((apartmentDetail) =>

                            <Card key={apartmentDetail.id} className='house'>
                                <Card.Body>

                                    <div className="image-container">
                                        <Link key={apartmentDetail.id} to={`/selected-property/${apartmentDetail.id}`} className=' text-decoration-none'>

                                            <Slider {...settings} interval={null}>{renderCarouselItems(apartmentDetail.id)}</Slider>
                                        </Link>
                                        <div className='heartBadge' onClick={() => toggleHearts(apartmentDetail.id)}>
                                            {heartState[apartmentDetail.id] ? (
                                                <div className='' style={{ width: "2vw", borderRadius: "8px" }} >
                                                    <img src={Heart} alt="Heart" />
                                                </div>) : (
                                                <div className='' style={{ width: "2vw", borderRadius: "8px" }} >
                                                    <img src={Heart2} alt="Heart2" />
                                                </div>
                                            )}
                                        </div>



                                        {/* <div className="badge">{apartmentDetail.houses_total} Units</div> */}
                                        <Link key={apartmentDetail.id} to={`/selected-property/${apartmentDetail.id}`} className=' text-decoration-none'>

                                            <div className='d-flex justify-content-between'>
                                                <p className='text-muted text-start mt-2 w-100' style={{ fontSize: "0.7rem" }}>{apartmentDetail.location},{apartmentDetail.county}</p>
                                            </div>
                                        </Link>

                                    </div>
                                    <p className='houseName' style={{ clear: "left", margin: "0", marginTop: "-1rem" }}>{apartmentDetail.name}</p>
                                    {typeof apartmentDetail.rent === 'string' && apartmentDetail.rent.includes('-') ?
                                        apartmentDetail.rent.split("-").map((rent, index) => (
                                            <p key={index} className='text-secondary float-start ' style={{ fontSize: "0.9rem" }}>
                                                {index === 0 ? ( // First part before the hyphen
                                                    `Ksh ${parseInt(rent).toLocaleString()}`
                                                ) : ( // Second part after the hyphen
                                                    `- Ksh ${parseInt(rent).toLocaleString()}/month`
                                                )}

                                            </p>

                                        ))
                                        :
                                        <p className='text-secondary float-start ' style={{ fontSize: "0.9rem" }}> Ksh. {apartmentDetail.rent.toLocaleString()}</p>

                                    }
                                    {/* {
                                            property.type_of_rent === "for_sale" ? (
                                                <span className='textRentProperty'>Ksh. {property.max_rent.toLocaleString()}</span>
                                            ) : (
                                                <span className='textRentProperty'>
                                                    {typeof property.max_rent === 'string' && property.max_rent.includes('-') ? (
                                                        property.max_rent.split("-").map((rent, index) => (
                                                            <span key={index}>
                                                                {index === 0 ? ( // First part before the hyphen
                                                                    parseInt(rent).toLocaleString()
                                                                ) : ( // Second part after the hyphen
                                                                    `-${parseInt(rent).toLocaleString()}/month`
                                                                )}
                                                            </span>
                                                        ))
                                                    ) : (
                                                        `Ksh. ${parseInt(property.max_rent).toLocaleString()}/month`
                                                    )}
                                                </span>
                                            )
                                        } */}
                                </Card.Body>
                            </Card>
                        )
                    }



                </div>

                {/* the pagination of the cards */}
                {landlordApartment.length > 8 ?
                    <div className="pagination" style={{ marginBottom: "2rem" }}>
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndex + 1}-${endIndex} of ${landlordApartment.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>
                    </div>

                    :
                    <>
                    </>}

            </div >
            <Button className='text-white bg-dark border-0 bottomButtonMap'>
                <Link to={'/map/listing'} className='text-white text-decoration-none'>
                    Show map
                    <Image src={Map} style={{ width: "20%", marginLeft: "0.2rem" }} />
                </Link>

            </Button>

        </>
    )
}

export default ListingPage




