import React, { useState } from 'react'
import '../assets/scss/Footer.scss'
import { Col, Container, NavLink, Row, Image, Modal, Form, Button } from 'react-bootstrap'
import { Link } from 'react-router-dom'
import Logo from '../assets/images/logo_1.png'

function Footer() {
    // the dropdown login part
    const [showLoginDropdown, setShowLoginDropdown] = useState(false);
    const [showUserLoginModal, setShowUserLoginModal] = useState(false);
    const [selectedCurrency, setSelectedCurrency] = useState('KSH');

    const handleUserLoginClick = () => {
        setShowUserLoginModal(true);
    };

    const handleCloseUserLoginModal = () => {
        setShowUserLoginModal(false);
    };

    const handleCurrencySelection = (currency) => {
        setSelectedCurrency(currency);
    };
    const handlePhoneClick = (phoneNumber) => {
        window.location.href = `tel:${phoneNumber}`;
    };

    const handleEmailClick = (emailAddress) => {
        window.location.href = `mailto:${emailAddress}`;
    };
    const currentYear = new Date().getFullYear();


    return (

        <>

            <div className='footer'>
                <Container fluid style={{ width: "98%" }}>
                    <Row>
                        {/* Head Offices - Nairobi */}
                        <Col sm={6} md={6} lg={2} xl={2}>
                            <h2>Head Offices - Nairobi</h2>
                            <p>Salama House, Mama Ngina Street</p>
                            <p>P.O. Box 17100 - 00100 Nairobi</p>
                            <p>
                                Phone No:{' '}
                                <a href="tel:0722498500" onClick={() => handlePhoneClick('0722498500')}>
                                    0722 498 500
                                </a>{' '}
                                /{' '}
                                <a href="tel:0720144566" onClick={() => handlePhoneClick('0720144566')}>
                                    0720 144 566
                                </a>
                            </p>
                            <p>
                                Email:{' '}
                                <a href="mailto:info@alliancerealtorsltd.co.ke" onClick={() => handleEmailClick('info@alliancerealtorsltd.co.ke')}>
                                    info@alliancerealtorsltd.co.ke
                                </a>
                            </p>
                        </Col>


                        {/* Embakasi Area Branch */}
                        <Col sm={6} md={6} lg={2} xl={2}>
                            <h2>Embakasi Branch</h2>
                            <p>Fedha Service Station Plaza, Ground Flr</p>
                            <p>P.O. Box 17100 - 00100 Nairobi</p>
                            <p>
                                Phone No:
                                <a href="tel:0701404440" onClick={() => handlePhoneClick('0701404440')}>
                                    0701 40 44 40
                                </a>
                            </p>
                            <p>
                                Email:{' '}
                                <a href="mailto:embakasi@alliancerealtorsltd.co.ke" onClick={() => handleEmailClick('embakasi@alliancerealtorsltd.co.ke')}>
                                    embakasi@alliancerealtorsltd.co.ke
                                </a>
                            </p>

                        </Col>

                        {/* Kiambu Area Branch */}
                        <Col sm={6} md={6} lg={2} xl={2}>
                            <h2>Kiambu Branch</h2>
                            <p>Feruzi Towers, 2nd Flr</p>
                            <p>
                                Phone No:
                                <a href="tel:0757355557" onClick={() => handlePhoneClick('0757355557')}>
                                    0757 35 55 57
                                </a>
                            </p>
                            <p>
                                Email:{' '}
                                <a href="mailto:feruzi@alliancerealtorsltd.co.ke" onClick={() => handleEmailClick('feruzi@alliancerealtorsltd.co.ke')}>
                                    feruzi@alliancerealtorsltd.co.ke
                                </a>
                            </p>
                        </Col>



                        {/* Kitengela */}
                        <Col sm={6} md={6} lg={2} xl={2}>
                            <h2>Kitengela Branch</h2>
                            <p>Eagle House, 2nd Flr</p>
                            <p>
                                Phone No:
                                <a href="tel:0798910910" onClick={() => handlePhoneClick('0798910910')}>
                                    0798 91 09 10
                                </a>
                            </p>
                            <p>
                                Email:{' '}
                                <a href="mailto:kitengela@alliancerealtorsltd.co.ke" onClick={() => handleEmailClick('kitengela@alliancerealtorsltd.co.ke')}>
                                    kitengela@alliancerealtorsltd.co.ke
                                </a>
                            </p>
                        </Col>



                        {/* Mombasa */}
                        <Col sm={6} md={6} lg={2} xl={2}>
                            <h2>Mombasa Branch</h2>
                            <p>Nyali Center, 2nd Flr</p>
                            <p>
                                Phone No:
                                <a href="tel:0758181818" onClick={() => handlePhoneClick('0758181818')}>
                                    0758 18 18 18 18
                                </a>
                            </p>
                            <p>
                                Email:{' '}
                                <a href="mailto:mombasa@alliancerealtorsltd.co.ke" onClick={() => handleEmailClick('mombasa@alliancerealtorsltd.co.ke')}>
                                    mombasa@alliancerealtorsltd.co.ke
                                </a>
                            </p>
                        </Col>

                        {/* Diaspora Liason */}
                        <Col sm={6} md={6} lg={2} xl={2}>
                            <h2>Diaspora Liaison</h2>
                            <p>
                                Phone No: <a href="tel:+14849957841">+14 84-995-7841</a>
                            </p>
                            <p>Philadelphia, USA</p>
                        </Col>


                    </Row>
                </Container>

                <Container fluid className='lowerFooter'>
                    <div className='d-flex' style={{ marginLeft: "6rem" }}>
                        <p className='mb-0'>Interact with us through:</p>
                        <Link to={`https://www.instagram.com/alliancerealtorsltd/`} target="_blank" rel="noopener noreferrer">
                            <i className="bi bi-instagram text-warning"></i>
                        </Link>
                        <Link to={`https://web.facebook.com/alliancerealtorsltd`} target="_blank" rel="noopener noreferrer">
                            <i class="bi bi-facebook text-warning"></i>
                        </Link>
                        <Link to={`https://www.tiktok.com/@alliancerealtors`} target="_blank" rel="noopener noreferrer">
                        <i class="bi bi-tiktok text-warning"></i>
                        </Link>

                    </div>

                    <div className='d-flex'>
                        <p onClick={handleUserLoginClick} className='d-none' style={{ textDecoration: 'underline', cursor: 'pointer' }}>
                            {selectedCurrency}<br />
                        </p>

                        <Modal show={showUserLoginModal} onHide={handleCloseUserLoginModal}>
                            <Modal.Header closeButton>
                                <Modal.Title>Choose a currency</Modal.Title>
                            </Modal.Header>
                            <Modal.Body>
                                <h5>Select your currency</h5>
                                <Form>
                                    <div className="d-flex mb-3">
                                        <Button
                                            className={`btn btn-outline-dark bg-transparent text-dark footer_buttons me-3`}
                                            onClick={() => handleCurrencySelection('KSH')}
                                        >
                                            Kenya Shillings<br />
                                            <span className='float-start'>KSH</span>
                                        </Button>
                                        <Button
                                            className={`btn btn-outline-dark bg-transparent text-dark footer_buttons`}
                                            onClick={() => handleCurrencySelection('USD - $')}
                                        >
                                            United States dollar<br />
                                            <span className='float-start'>USD - $</span>
                                        </Button>
                                    </div>
                                </Form>
                            </Modal.Body>
                        </Modal>

                        <Link to='https://www.kodinyumba.com/' target="_blank" rel="noopener noreferrer">
                            <p>© {currentYear} powered by Kodi. All rights reserved.</p>

                        </Link>

                        <p>
                            <a href="tel:0722498500" >Support Center</a>
                        </p>
                    </div>
                </Container>
            </div>
        </>
    )
}

export default Footer