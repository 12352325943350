import React from 'react'
import { Button, Card, Col, Row } from 'react-bootstrap'
import '../../assets/scss/ServiceManagement.scss'
import { Link } from 'react-router-dom';

function ServiceManagement() {
    return (
        <div className='propertyManagement' id="serviceManagementSection" >
            <div className='topRowDisplay' >
                <h2>PPROPERTY & ASSET MANAGEMENT</h2>
                <p>We offer professional expertise in management of sensitive commercial & residential properties including controlled/protected tenancies,
                    observe legal requirements appertaining to real property hence skilful handling of court cases when they arise. Our services include:</p>
            </div>


            <Row>
                <Col md={4} sm={12} xm={12} className='col-hover'>
                    <Card>

                        <i class="bi bi-gear-fill"></i>
                        <h2>REPAIRS & MAINTENANCE</h2>
                        <Link to={'/profile'} className='text-decoration-none'>
                            <Button>CONTINUE</Button>
                        </Link>
                    </Card>

                </Col>

                <Col md={4} sm={12} xm={12} className='col-hover'>
                    <Card>
                        <i class="bi bi-gear-fill"></i>
                        <h2>KEEPING OF RECORDS</h2>
                        <Link to={'/profile'} className='text-decoration-none'>
                            <Button>CONTINUE</Button>
                        </Link>
                    </Card>

                </Col>

                <Col md={4} sm={12} xm={12} className='col-hover'>
                    <Card>
                        <i class="bi bi-gear-fill"></i>
                        <h2>PAYMENT OF BILLS & LEVIES</h2>
                        <Link to={'/profile'} className='text-decoration-none'>
                            <Button>CONTINUE</Button>
                        </Link>
                    </Card>

                </Col>
            </Row>

            <Row>
                <Col md={4} sm={12} xm={12} className='col-hover'>
                    <Card>
                        <i class="bi bi-gear-fill"></i>
                        <h2>RENT COLLECTION</h2>
                        <Link to={'/profile'} className='text-decoration-none'>
                            <Button>CONTINUE</Button>
                        </Link>
                        
                    </Card>

                </Col>

                <Col md={4} sm={12} xm={12} className='col-hover'>
                    <Card>
                        <i class="bi bi-gear-fill"></i>
                        <h2>BI-ANNUAL INSPECTIONS</h2>
                        <Link to={'/profile'} className='text-decoration-none'>
                            <Button>CONTINUE</Button>
                        </Link>
                                            </Card>

                </Col>

                <Col md={4} sm={12} xm={12} className='col-hover'>
                    <Card>
                        <i class="bi bi-gear-fill"></i>
                        <h2>RENT REVIEWS</h2>
                        <Link to={'/profile'} className='text-decoration-none'>
                            <Button>CONTINUE</Button>
                        </Link>
                                            </Card>

                </Col>
            </Row>

            <Row>
                <Col md={4} sm={12} xm={12} className='col-hover'>

                    <Card>
                        <i class="bi bi-gear-fill"></i>
                        <h2>SECURITY INSUARANCE</h2>
                        <Link to={'/profile'} className='text-decoration-none'>
                            <Button>CONTINUE</Button>
                        </Link>
                                            </Card>

                </Col>

                <Col md={4} sm={12} xm={12} className='col-hover'>
                    <Card>
                        <i class="bi bi-gear-fill"></i>
                        <h2>PREPARATION OF STATEMENTS OF ACCOUNTS</h2>
                        <Link to={'/profile'} className='text-decoration-none'>
                            <Button>CONTINUE</Button>
                        </Link>
                                            </Card>

                </Col>

                <Col md={4} sm={12} xm={12} className='col-hover'>
                    <Card>
                        <i class="bi bi-gear-fill"></i>
                        <h2>RENT COLLECTION</h2>
                        <Link to={'/profile'} className='text-decoration-none'>
                            <Button>CONTINUE</Button>
                        </Link>
                                            </Card>

                </Col>
            </Row>
        </div>
    )
}

export default ServiceManagement