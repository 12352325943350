import React, { useState, useEffect } from 'react';
import { Button, Form, InputGroup, Image, Offcanvas, Row, Col, Modal } from 'react-bootstrap';
import '../assets/scss/Profile.scss';
import { serverUrl, landlordId } from '../serverUrl';
import { toast } from "react-toastify";
import { useNavigate } from 'react-router-dom';
import { Link } from 'react-router-dom';
// import * as pdfjsLib from 'pdfjs-dist';
import CompanyProfile from '../assets/documents/company_profile.pdf'
// import PdfViewer from './PDFViewer';
import Profile1 from '../assets/images/company_profile/company_profile_page-0001.jpg'
import Profile2 from '../assets/images/company_profile/company_profile_page-0002.jpg'
import Profile3 from '../assets/images/company_profile/company_profile_page-0003.jpg'
import Profile4 from '../assets/images/company_profile/company_profile_page-0004.jpg'
import Profile5 from '../assets/images/company_profile/company_profile_page-0005.jpg'
import Profile6 from '../assets/images/company_profile/company_profile_page-0006.jpg'
import Profile7 from '../assets/images/company_profile/company_profile_page-0007.jpg'
import Profile8 from '../assets/images/company_profile/company_profile_page-0008.jpg'
import Profile9 from '../assets/images/company_profile/company_profile_page-0010.jpg'
import Profile10 from '../assets/images/company_profile/company_profile_page-0011.jpg'
import Profile11 from '../assets/images/company_profile/company_profile_page-0018.jpg'



function Profile() {
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    name: '',
    location: '',
    email: '',
    service: '',
    phone: '',
  });

  const handleChange = (e) => {
    const { name, value } = e.target;
    setFormData({
      ...formData,
      [name]: value,
    });
  };

  useEffect(() => {
    // const pdfViewer = document.getElementById('pdf-viewer');

    try {
      const pdfArray = new Uint8Array(CompanyProfile.length);

      for (let i = 0; i < CompanyProfile.length; i++) {
        pdfArray[i] = CompanyProfile.charCodeAt(i);
      }

      // Initialize PDF.js with the embedded PDF content
      // pdfjsLib.getDocument({ data: pdfArray }).then((pdf) => {
      //   // Fetch the first page
      //   pdf.getPage(1).then((page) => {
      //     const viewport = page.getViewport({ scale: 1.5 });
      //     const canvas = document.createElement('canvas');
      //     const context = canvas.getContext('2d');
      //     canvas.width = viewport.width;
      //     canvas.height = viewport.height;

      //     // Render the page content into the canvas
      //     const renderContext = {
      //       canvasContext: context,
      //       viewport: viewport,
      //     };
      //     page.render(renderContext);

      //     // Append the canvas to the container
      //     // pdfViewer.appendChild(canvas);
      //   });
      // });
    } catch (error) {
      console.error('Error initializing PDF:', error);
    }
  }, [CompanyProfile]);



  const [selectedButtons, setSelectedButtons] = useState([]);

  const handleButtonClick = (buttonType) => {
    if (selectedButtons.includes(buttonType)) {
      setSelectedButtons(selectedButtons.filter((btn) => btn !== buttonType));
    } else {
      setSelectedButtons([...selectedButtons, buttonType]);
    }
  };

  const selectedButtonsString = selectedButtons.join(', ');


  const handleSubmit = (e) => {
    e.preventDefault();

    // data to be sent to backend
    const data = {
      name: formData.name,
      email: formData.email,
      phone: formData.phone,
      location: formData.location,
      service: selectedButtonsString,
      // landlord: landlordId
    }

    serverUrl.post(`/landlord/alliance-post-landlord-lead/`, data)
      .then((res) => {
        toast.success('Succesfully sent your data our team will reach out to you shortly')
        navigate('/')
      })
      .catch((error) => [
        console.log(error)
      ])
    // You can handle form submission logic here
    // Close the off-canvas after submission
  };

  const [show, setShow] = useState(true);

  const handleClose = () => {
    setShow(false);
    document.body.classList.remove('offcanvas-open');
    document.body.style.overflow = 'auto !important';

  };

  const handleShow = () => {
    setShow(true);
    document.body.classList.add('offcanvas-open');
    document.body.style.overflow = 'auto !important';

  };
  const handleExited = () => {
    document.body.classList.remove('offcanvas-open');
    document.body.style.overflow = 'auto';
  };

  const [showModal, setShowModal] = useState(false);
  const [showImages, setShowImages] = useState(false);
  const [showModalImages, setShowModalImages] = useState(false);

  const handleCloseModal = () => setShowModal(false);
  const handleShowModal = () => {
    setShowModal(true);
    setShowImages(true);
  };

  const handleShowModalImages = () => {
    setShowModalImages(true);
  };
  const handleCloseModalImages = () => {
    setShowModalImages(false);
  };
  const handleToggleImages = () => setShowImages(!showImages);

  return (
    <div className="profile">
      <div className='d-flex'>
        <Link to={`/`} className='addHouse ms-2'><i className='bi bi-arrow-left'></i></Link>
        <h2 className='text-warning fw-bold' style={{ margin: "0 auto", paddingTop: "1rem" }}>OUR COMPANY PROFILE</h2>

        <Button variant="success" onClick={handleShow} className='text-white btn btn-sm me-2' style={{ height: "fit-content", marginTop: "0.8rem" }}>
          Get Started
        </Button>
      </div>



      <Offcanvas show={show} onHide={handleClose} className='offcanvasDisplayProfile'>
        <Offcanvas.Header closeButton>
          <Offcanvas.Title>Kindly fill in the form and someone will get in touch with you</Offcanvas.Title>
        </Offcanvas.Header>
        <Offcanvas.Body>
          <Row>
            <Col md={6} xl={6} sm={6}>
              <Form onSubmit={handleSubmit}>
                <Form.Group controlId="name">
                  <Form.Label>Name</Form.Label>
                  <Form.Control
                    type="text"
                    name="name"
                    value={formData.name}
                    onChange={handleChange}
                    required
                    className='w-100 mb-3'
                  />
                </Form.Group>
            
                <Form.Group controlId="email">
                  <Form.Label>Email</Form.Label>
                  <Form.Control
                    type="email"
                    name="email"
                    value={formData.email}
                    onChange={handleChange}
                    required
                    className='w-100 mb-3'
                  />
                </Form.Group>
                <Form.Group controlId="email">
                  <Form.Label>Phone Number</Form.Label>
                  <Form.Control
                    type="phone"
                    name="phone"
                    value={formData.phone}
                    onChange={handleChange}
                    required
                    className='w-100 mb-3'
                  />
                </Form.Group>
                <Form.Group controlId="Feedback">
                  <Form.Label>Feedback</Form.Label>
                  <Form.Control
                    type="text"
                    name="location"
                    value={formData.location}
                    onChange={handleChange}
                    required
                    className='w-100 mb-3'
                  />
                </Form.Group>
                
                <Form.Label>Services</Form.Label>

                <InputGroup className='justify-content-center ProfileInputGroup'>
                  <Button
                    style={{ width: '25%', borderColor: 'lightgray' }}
                    className={`btn btn-outline-secondary ${selectedButtons.includes('Property & asset valuation') ? 'typeActive text-white' : 'text-dark bg-transparent'
                      } p-3`}
                    onClick={() => handleButtonClick('Property & asset valuation')}
                  >
                    Property & asset valuation
                  </Button>
                  <Button
                    style={{ width: '25%', borderColor: 'lightgray' }}
                    className={`btn btn-outline-secondary ${selectedButtons.includes('Property & asset management') ? 'typeActive text-white' : 'text-dark bg-transparent'
                      }`}
                    onClick={() => handleButtonClick('Property & asset management')}
                  >
                    Property & asset management
                  </Button>
                  <Button
                    style={{ width: '25%', borderColor: 'lightgray' }}
                    className={`btn btn-outline-secondary ${selectedButtons.includes('Real estate agency') ? 'typeActive text-white' : 'text-dark bg-transparent'
                      }`}
                    onClick={() => handleButtonClick('Real estate agency')}
                  >
                    Real estate agency
                  </Button>
                </InputGroup>


                <div className="d-flex mt-2 justify-content-between">
                  <Button variant="warning" type="submit" className="text-white">
                    Submit
                  </Button>
                </div>
              </Form>
            </Col>

            <Col md={6} xl={6} sm={6} className="text-center">
              {!showImages && (
                <Button variant="warning" onClick={handleShowModal} className="mt-3 text-white">
                  Open Company Profile
                </Button>
              )}
              {showImages && (
                <div className="image-container">
                  <div className="image-container">
                    <Button variant="success" onClick={handleToggleImages} className="close-btn" style={{
                      display: "flex",
                      position: "relative",
                      clear: "right"
                    }}>
                      Close
                    </Button>

                    <div style={{ height: '80vh', overflowY: 'auto' }}>
                      <Image src={Profile1} className='w-100 mb-3' alt="Image 1" />
                      <Image src={Profile2} className='w-100 mb-3' alt="Image 2" />
                      <Image src={Profile3} className='w-100 mb-3' alt="Image 3" />
                      <Image src={Profile4} className='w-100 mb-3' alt="Image 4" />
                      <Image src={Profile5} className='w-100 mb-3' alt="Image 5" />
                      <Image src={Profile6} className='w-100 mb-3' alt="Image 6" />
                      <Image src={Profile7} className='w-100 mb-3' alt="Image 7" />
                      <Image src={Profile8} className='w-100 mb-3' alt="Image 8" />
                      <Image src={Profile9} className='w-100 mb-3' alt="Image 9" />
                      <Image src={Profile10} className='w-100 mb-3' alt="Image 10" />
                      <Image src={Profile11} className='w-100 mb-3' alt="Image 11" />
                    </div>
                  </div>
                </div>
              )}
            </Col>

          </Row>

        </Offcanvas.Body>
      </Offcanvas>

      <div style={{ position: "relative" }}>
        <Image src={Profile1} className='w-100 mb-3' alt="Image 1" style={{
          height: "115vh",
        }} />
        <div style={{
          position: "absolute",
          top: "50%",
          left: "50%",
          transform: "translate(-50%, -50%)",
          zIndex: 1,
        }}>
          <Button variant="warning" className='text-white' onClick={handleShowModalImages}>
            Open Company Profile
          </Button>


        </div>
        <Modal show={showModalImages} onHide={handleCloseModalImages} centered size='xl'>
          <Modal.Header closeButton>
            <Modal.Title>Company Profile</Modal.Title>
          </Modal.Header>
          <Modal.Body>
            <Image src={Profile1} className='w-100 mb-3' alt="Image 1" />
            <Image src={Profile2} className='w-100 mb-3' alt="Image 2" />
            <Image src={Profile3} className='w-100 mb-3' alt="Image 3" />
            <Image src={Profile4} className='w-100 mb-3' alt="Image 4" />
            <Image src={Profile5} className='w-100 mb-3' alt="Image 5" />
            <Image src={Profile6} className='w-100 mb-3' alt="Image 6" />
            <Image src={Profile7} className='w-100 mb-3' alt="Image 7" />
            <Image src={Profile8} className='w-100 mb-3' alt="Image 8" />
            <Image src={Profile9} className='w-100 mb-3' alt="Image 9" />
            <Image src={Profile10} className='w-100 mb-3' alt="Image 10" />
            <Image src={Profile11} className='w-100 mb-3' alt="Image 11" />
          </Modal.Body>
          <Modal.Footer>
            {/* Your modal footer, if needed */}
            <Button variant="secondary" onClick={handleCloseModalImages}>
              Close
            </Button>
          </Modal.Footer>
        </Modal>
      </div>




    </div>
  );
}

export default Profile;
