import React, { useEffect, useState } from 'react'
import '../assets/scss/Favourites.scss'
import NavigationBar from '../components/NavigationBar'
import { Card, Image, Modal, Button, Nav } from 'react-bootstrap'
import { serverUrl } from '../serverUrl'
import { jwtDecode } from 'jwt-decode';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import Heart from '../assets/images/heart__1_.png'
import { Link, useParams } from 'react-router-dom'
import { toast } from 'react-toastify'

function Favourites() {

    // get user details so that you can link with the Apartment from the access tokens
    const accessToken = localStorage.getItem("authTokens");

    const parsedTokens = JSON.parse(accessToken);
    const access = parsedTokens.access;
    // headers access token
    const config = {
        headers: { Authorization: `Bearer ${access}` }
    }
    // decoding the token so that i can get the user id
    const decodedToken = jwtDecode(accessToken);
    const userId = decodedToken.user_id;

    // get the liked properties and units
    const [likedUnits, setLikedUnits] = useState([])
    const [likedProperties, setLikedProperties] = useState([])

    // the details of the apartment and units

    useEffect(() => {
        serverUrl.get(`/landlord/viewers-favourites/?user=${userId}`, config)
            .then((res) => {
                const units = res.data.results.filter((units) => units.apartment === null)
                // the units obtained
                const housePromises = units.map((house) =>
                    serverUrl.get(`/landlord/alliance-specific-house?unit_id=${house.house}`, config)
                        .then((res) => res.data.results)
                        .catch((error) => {
                            console.log(error);
                            return null;
                        })
                );

                Promise.all(housePromises)
                    .then((houseResults) => {
                        const filteredHouseResults = houseResults.filter((house) => house !== null);
                        setLikedUnits(filteredHouseResults);
                    })
                    .catch((error) => {
                        console.log(error);
                    });

                // the properties obtained
                const apartments = res.data.results.filter((apartment) => apartment.house === null)
                const apartmentPromises = apartments.map((apartment) =>
                    serverUrl.get(`/landlord/alliance-specific-apartment?property_id=${apartment.apartment}`, config)
                        .then((res) => res.data.results)
                        .catch((error) => {
                            console.log(error);
                            return null;
                        })
                );

                Promise.all(apartmentPromises)
                    .then((apartmentResults) => {
                        const filteredApartmentResults = apartmentResults.filter((apartment) => apartment !== null);
                        setLikedProperties(filteredApartmentResults);
                    })
                    .catch((error) => {
                        console.log(error);
                    });
            })
            .catch((error) => {
                console.log(error)
            })


    }, [userId])

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };

    // get to display the images of the apartments 
    const renderCarouselItems = (apartmentId) => {
        // const uniqueImages = backgroundImageApartment[apartmentId] || [];
        const uniqueImages = likedProperties.filter((apartment) => apartment[0].id === apartmentId)
        const Images = uniqueImages[0][0].property_image

        return Images.map((imageUrl, index) => (
            <Slider {...settings}>

                <Image src={imageUrl.image} alt={`Image ${index + 1}`} fluid />

            </Slider>
        ));
    };

    // get to display the images of the house
    const renderCarouselItemsHouse = (houseId) => {
        const uniqueImages = likedUnits.filter((house) => house[0].id === houseId)
        const Images = uniqueImages[0][0].unit_images
        return Images.map((imageUrl, index) => (
            <Slider {...settings}>

                <Image src={imageUrl.image} alt={`Image ${index + 1}`} fluid />

            </Slider>
        ));
    };

    // modal for deleting favourites apartment
    const [showModalDelete, setShowModalDelete] = useState(false);
    const [deleteFavourite, setDelete] = useState(null);

    const handleOpenModalDelete = unitDetail => {
        setDelete(unitDetail);
        setShowModalDelete(true);
    };
    const handleCloseModalDelete = () => {
        setShowModalDelete(false);
    };

    const handleDelete = () => {
        // get the landlord details of the user
        console.log(deleteFavourite)
        // get the data of the viewer favourites
        serverUrl.get(`/landlord/viewers-favourites/?apartment=${deleteFavourite}`, config)
            .then((res) => {
                serverUrl.delete(`/landlord/viewers-favourites/${res.data.results[0].id}/`, config)
                    .then((res) => {
                        setShowModalDelete(false);
                        toast.success('Succesfully deleted the property from the favourites')

                        // get the new data
                        serverUrl.get(`/landlord/viewers-favourites/?user=${userId}`, config)
                            .then((res) => {
                                // the properties obtained
                                const apartments = res.data.results.filter((apartment) => apartment.house === null)
                                const apartmentPromises = apartments.map((apartment) =>
                                    serverUrl.get(`/landlord/alliance-specific-apartment?property_id=${apartment.apartment}`, config)
                                        .then((res) => res.data.results)
                                        .catch((error) => {
                                            console.log(error);
                                            return null;
                                        })
                                );

                                Promise.all(apartmentPromises)
                                    .then((apartmentResults) => {
                                        const filteredApartmentResults = apartmentResults.filter((apartment) => apartment !== null);
                                        setLikedProperties(filteredApartmentResults);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })

    }

    // modal for deleting favourites unit
    const [showModalDeleteUnit, setShowModalDeleteUnit] = useState(false);

    const handleOpenModalDeleteUnit = unitDetail => {
        setDelete(unitDetail);
        setShowModalDeleteUnit(true);
    };
    const handleCloseModalDeleteUnit = () => {
        setShowModalDeleteUnit(false);
    };

    const handleDeleteUnit = () => {
        // get the landlord details of the user
        console.log(deleteFavourite)
        // get the data of the viewer favourites
        serverUrl.get(`/landlord/viewers-favourites/?house=${deleteFavourite}`, config)
            .then((res) => {
                serverUrl.delete(`/landlord/viewers-favourites/${res.data.results[0].id}/`, config)
                    .then((res) => {
                        setShowModalDeleteUnit(false);
                        toast.success('Succesfully deleted the unit from the favourites')

                        // get the new data
                        serverUrl.get(`/landlord/viewers-favourites/?user=${userId}`, config)
                            .then((res) => {
                                const units = res.data.results.filter((units) => units.apartment === null)
                                // the units obtained
                                const housePromises = units.map((house) =>
                                    serverUrl.get(`/landlord/alliance-specific-house?unit_id=${house.house}`, config)
                                        .then((res) => res.data.results)
                                        .catch((error) => {
                                            console.log(error);
                                            return null;
                                        })
                                );

                                Promise.all(housePromises)
                                    .then((houseResults) => {
                                        const filteredHouseResults = houseResults.filter((house) => house !== null);
                                        setLikedUnits(filteredHouseResults);
                                    })
                                    .catch((error) => {
                                        console.log(error);
                                    });
                            })
                            .catch((error) => {
                                console.log(error)
                            })
                    })
                    .catch((error) => {
                        console.log(error)
                    })
            })
            .catch((error) => {
                console.log(error)
            })

    }

    // the tabs for properties and units
    const [selectedCategory, setSelectedCategory] = useState('properties');
    const [selectedItem, setSelectedItem] = useState(null);
    const goBack = () => {
        window.history.back();
    };

    return (
        <>
            <p className="topNavigation" style={{ padding: "0rem", zIndex: "30000" }} onClick={goBack}>
                <i class="bi bi-arrow-left-circle"></i>
            </p>
            <NavigationBar />
            <div className='favourites'>

                <h2>My Favourite Property and Units</h2>


                {/* <div className='d-flex justify-content-between w-75 mt-3 mb-3'>
                    <h3
                        onClick={() => setSelectedCategory('properties')}
                        className={selectedCategory === 'properties' ? 'activeFavourite' : ''}
                    >
                        Liked Properties
                    </h3>
                    <h3
                        onClick={() => setSelectedCategory('units')}
                        className={selectedCategory === 'units' ? 'activeFavourite' : ''}
                    >
                        Liked Units
                    </h3>
                </div> */}
                <div className='w-75 mt-3 mb-3'>
                    <Nav variant="tabs">
                        <Nav.Item className='tabsViewDisplay' >
                            <Nav.Link
                                eventKey="properties"
                                onClick={() => setSelectedCategory('properties')}
                                className={selectedCategory === 'properties' ? 'activeFavourite' : 'currentTab'}
                            >
                                Liked Properties
                            </Nav.Link>
                        </Nav.Item>
                        <Nav.Item className='tabsViewDisplay' >
                            <Nav.Link
                                eventKey="units"
                                onClick={() => setSelectedCategory('units')}
                                className={selectedCategory === 'units' ? 'activeFavourite' : 'currentTab'}
                            >
                                Liked Units
                            </Nav.Link>
                        </Nav.Item>
                    </Nav>
                </div>



                {selectedCategory === 'properties' &&
                    <div>
                        {likedProperties.length > 0 ?
                            <>
                                <div className='favouriteDivs'>
                                    {likedProperties.map((apartment) => (
                                        <>

                                            <Card key={apartment[0].id} className='house'>
                                                <Card.Body>

                                                    <div className="image-container">
                                                        <Slider {...settings} interval={null}>{renderCarouselItems(apartment[0].id)}</Slider>

                                                        <div className='heartBadge'>
                                                            <img src={Heart} alt="Heart" />

                                                        </div>


                                                        <div className='d-flex justify-content-between mt-3'>
                                                            <p className='text-muted text-start mt-2 w-100' style={{ fontSize: "0.8rem" }}>{apartment[0].location},{apartment[0].county}</p>
                                                        </div>


                                                    </div>
                                                    <p className='houseName' style={{ clear: "left", margin: "0", marginTop: "-1rem" }}>{apartment[0].name}</p>
                                                    <div className='d-flex justify-content-end'>
                                                        <Link key={apartment[0].id} to={`/selected-property/${apartment[0].id}`} className=' text-decoration-none'>
                                                            View Property
                                                        </Link>
                                                        <i className='bi bi-trash-fill text-danger' onClick={() => handleOpenModalDelete(apartment[0].id)}></i>
                                                    </div>
                                                </Card.Body>
                                            </Card>

                                            <Modal show={showModalDelete} onHide={handleCloseModalDelete}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Delete Favaourite</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>Do you want to delete this property from favourites?</Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="success" onClick={handleCloseModalDelete}>
                                                        No
                                                    </Button>
                                                    <Button DeleteId={apartment[0].id} variant="danger" onClick={handleDelete}>
                                                        Yes
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>
                                        </>

                                    ))}
                                </div>
                            </>
                            :
                            <>
                                <p className='textNotFound'>You have not liked any property</p>
                            </>
                        }


                    </div>

                }
                {selectedCategory === 'units' &&

                    <div>
                        {likedUnits.length > 0 ?
                            <>
                                <div className='favouriteDivs'>
                                    {likedUnits.map((house) => (
                                        <>
                                            <Card key={house[0].id} className='house'>
                                                <Card.Body>
                                                    <div className="image-container">
                                                        <Slider {...settings} interval={null}>{renderCarouselItemsHouse(house[0].id)}</Slider>
                                                        <div className='heartBadge'>
                                                            <img src={Heart} alt="Heart" />

                                                        </div>


                                                        <div className='d-flex justify-content-between mt-3'>
                                                            <p className='text-muted text-start mt-2 w-100' style={{ fontSize: "0.8rem" }}>{house[0].location},{house[0].county}</p>
                                                        </div>
                                                        <p className='houseName' style={{ clear: "left", margin: "0", marginTop: "-1rem" }}>{house[0].name},{house[0].apartment}</p>
                                                        <div className='d-flex justify-content-end'>
                                                            <Link key={house[0].id} to={`/selected-house/${house[0].id}`} className=' text-decoration-none'>
                                                                View Unit
                                                            </Link>
                                                            <i className='bi bi-trash-fill text-danger' onClick={() => handleOpenModalDeleteUnit(house[0].id)}></i>
                                                        </div>

                                                    </div>
                                                </Card.Body>
                                            </Card>


                                            <Modal show={showModalDeleteUnit} onHide={handleCloseModalDeleteUnit}>
                                                <Modal.Header closeButton>
                                                    <Modal.Title>Delete Favourite</Modal.Title>
                                                </Modal.Header>
                                                <Modal.Body>Do you want to delete this unit from favourites?</Modal.Body>
                                                <Modal.Footer>
                                                    <Button variant="success" onClick={handleCloseModalDeleteUnit}>
                                                        No
                                                    </Button>
                                                    <Button DeleteId={house[0].id} variant="danger" onClick={handleDeleteUnit}>
                                                        Yes
                                                    </Button>
                                                </Modal.Footer>
                                            </Modal>

                                        </>

                                    ))}
                                </div>
                            </>
                            :
                            <>
                                <p className='textNotFound'>You have not liked any unit</p>

                            </>
                        }


                    </div>
                }



            </div>
        </>
    )
}

export default Favourites