import React, { useState, useEffect } from 'react'
import NavigationBar from '../components/NavigationBar'
import { Button, Card, Container, Form, FormControl, Image, Modal, Carousel } from 'react-bootstrap';
import '../assets/scss/AllBlogs.scss'
import { serverUrl } from '../serverUrl';
import { Link, useParams } from 'react-router-dom'
import BlogFilter from '../components/BlogFilter';

function AllBlogs(props) {
    // randomise the images for my properties
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 10) + 1;
            while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
        };

        getRandomImage();
    }, []);

    // get the blogs
    const [blogs, setBlogs] = useState([])
    useEffect(() => {
        serverUrl.get(`/landlord/alliance-blog-image-user`)
            .then((res) => {
                setBlogs(res.data.blogs)
            })
            .catch((error) => {
                console.log(error)
            })
    }, [])

    const [dataFromSearch, setDataFromSearch] = useState('');
    // Function to handle data from the child
    const handleSearchData = (searchData) => {
        setDataFromSearch(searchData);
    };
    function containsSearchQuery(item, dataFromSearch) {
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(dataFromSearch.toLowerCase());
    }

    const [selectedBlogCategory, setBlogCategory] = useState(null);

    // Create a Set to store unique location status
    const uniqueBlogCategory = new Set();

    // Filter houses array to get unique rent amounts
    const uniqueCategory = blogs.filter((category) => {
        if (!uniqueBlogCategory.has(category.category)) {
            uniqueBlogCategory.add(category.category);
            return true;
        }
        return false;
    });


    // on search of the properties get to list the properties
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null);
    };

    const filteredData = blogs.filter((item) => {
        // Filter by selected rent
        if (selectedBlogCategory && item.category !== selectedBlogCategory) {
            return false;
        }
        // Filter by searchQuery if it's not blank
        if (dataFromSearch && !containsSearchQuery(item, dataFromSearch)) {
            return false;
        }
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(searchQuery.toLowerCase());
    });
    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 8;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);

    return (
        <>
            <BlogFilter />
            <NavigationBar onData={handleSearchData} {...props} />
            <div className='blogsAll'>
                <div className='property'>
                    <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
                        <p className='properties' style={{ padding: "4.5rem" }}>All BLOGS</p>

                    </div>

                    <Card className='cardProperty'>

                        <Form.Select size='sm' className='formProperty' value={selectedBlogCategory} onChange={(e) => setBlogCategory((e.target.value))} >
                            <option disabled selected>By Duration</option>
                            <option value="">All Blogs</option>
                            <option value="">Month</option>
                            <option value="">Past 6 months</option>
                            <option value="">Past year</option>
                        </Form.Select>

                        <Form.Select size='sm' className='formProperty' style={{ justifySelf: "right" }} value={selectedBlogCategory} onChange={(e) => setBlogCategory((e.target.value))} >
                            <option disabled selected>By Category</option>
                            <option value="">All Blogs</option>
                            {uniqueCategory.map((blog) => (
                                <option value={blog.category}>{blog.category}</option>
                            ))}

                        </Form.Select>
                    </Card>


                    {/* the cards that have the houses and their properties */}
                    <div className='housesCards'>
                        {!displayedItems && blogs.length === 0 ?
                            <p className='notfoundp'>Sorry no blogs were found</p>
                            :
                            displayedItems.map((blog) =>

                                <Card key={blog.id} className='house'>
                                    <Link to={`/single-blog/${blog.id}`} className='text-decoration-none text-dark'>

                                        <Card.Body>
                                            <Image src={blog.blog_image} />
                                            <div className='d-flex justify-content-between'>
                                                <h2>{blog.blog}</h2>
                                                <h2 className='text-success'>{blog.category}</h2>

                                            </div>
                                            <p>{blog.description}</p>
                                        </Card.Body>

                                        <Card.Footer>
                                            <div className='footerWhyUs'>
                                                <Image src={blog.user_profile} />
                                                <p>{blog.user}</p>
                                            </div>

                                            <p>{new Date(blog.created).toLocaleDateString('en-US', {
                                                month: 'long',
                                                day: 'numeric',
                                                year: '2-digit'
                                            })}</p>
                                        </Card.Footer>
                                    </Link>
                                </Card>

                            )
                        }



                    </div>
                </div>
            </div>
        </>
    )
}

export default AllBlogs