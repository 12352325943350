import React, { useState, useEffect } from "react";
import {
  Button,
  Card,
  Dropdown,
  Tab,
  Tabs,
  Modal,
  Image,
  Col,
  Row,
  InputGroup,
  Form,
} from "react-bootstrap";
import "../assets/scss/HomepageSearch.scss";
import { Link } from "react-router-dom";
import { serverUrl, landlordId, updateLandlordId } from "../serverUrl";
import InputRange from "react-input-range";
import { useNavigate } from "react-router";

function HomePageSearch(props) {
  const navigate = useNavigate();

  const [propertyType, setPropertyType] = useState("");

  const handlePropertyChange = (e) => {
    setPropertyType(e.target.value);
  };

  const [countyType, setCountyType] = useState("");

  const handleCountyChange = (e) => {
    setCountyType(e.target.value);
  };

  const [areaType, setAreaType] = useState("");

  const handleAreaChange = (e) => {
    setAreaType(e.target.value);
  };

  // get the search items using a form data method
  const [formData, setFormData] = useState({});

  const handleChange = (event) => {
    const { name, value } = event.target;
    setFormData((prevFormData) => ({
      ...prevFormData,
      [name]: value,
    }));
  };
  // get the properties that are featured
  const [properties, setProperties] = useState([]);
  const [counties, setCounties] = useState([]);
  const [areaProperty, setAreaProperty] = useState([]);

  useEffect(() => {
    serverUrl.get(`/landlord/all-landlords/`)
      .then((res) => {
        setProperties(res.data.results);
      })
      .catch((error) => {
        console.log(error);
      });

    //   get the counties within the system
    serverUrl.get(`/landlord/alliance-county-location`)
      .then((res) => {

        setCounties(res.data.results);
      })
      .catch((error) => {
        console.log(error);
      });

    //   the subcounties for the counties selected
    serverUrl.get(`/landlord/alliance-county-location?county_name=${countyType}`)
      .then((res) => {
        setAreaProperty(res.data.results)
      })
      .catch((error) => {
        console.log(error)
      })
    // landlordId.map((data) => {



    // })
  }, [countyType])



  // Filter properties to exclude "None" type_of_apartment
  const filteredProperties = properties.filter(
    (property) => property.type_of_apartment !== null
  );

  // Create uniquePropertyTypes from filteredProperties
  const uniquePropertyTypes = [
    ...new Set(
      filteredProperties.map((property) => property.type_of_apartment)
    ),
  ];

  // functionalities for the filtering modal
  const [showModal, setShowModal] = useState(false);

  const handleShowModal = () => {
    setShowModal(true);
  };

  const handleCloseModal = () => {
    setShowModal(false);
  };

  const items = [
    {
      description: "Apartment Units",
      name: "apartment_units",

      image: "https://kodinyumba.app/media/house_metric_icons/apartment.png",
    },
    {
      description: "Studio",
      name: "studio",

      image: "https://kodinyumba.app/media/house_metric_icons/studio.png",
    },
    {
      description: "Bungalow",
      name: "bungalow",

      image: "https://kodinyumba.app/media/house_metric_icons/bungalow.png",
    },
    {
      description: "Bedistter",
      name: "bedsitter",

      image: "https://kodinyumba.app/media/house_metric_icons/bedsitter.png",
    },
    {
      description: "Servant Quarters(SQ)",
      name: "servant_quarters",

      image:
        "https://kodinyumba.app/media/house_metric_icons/servant-quarter.png",
    },
    {
      description: "Mansionette",
      name: "mansionette",

      image: "https://kodinyumba.app/media/house_metric_icons/mansionette.png",
    },
    {
      description: "Office",
      name: "office",

      image: "https://kodinyumba.app/media/house_metric_icons/office.png",
    },
    {
      description: "Stall",
      name: "stall",

      image: "https://kodinyumba.app/media/house_metric_icons/stall.png",
    },
    {
      description: "Shop",
      name: "shop",

      image: "https://kodinyumba.app/media/house_metric_icons/shop.png",
    },
  ];

  const settings = {
    dots: false,
    infinite: false,
    speed: 500,
    slidesToShow: 9,
    slidesToScroll: 1,
    responsive: [
      {
        breakpoint: 768,
        settings: {
          slidesToShow: 3,
          slidesToScroll: 1,
        },
      },
    ],
  };

  const [activeButton, setActiveButton] = useState(null);

  const handleButtonClick = (buttonName) => {
    setActiveButton(buttonName);
  };

  const [priceRange, setPriceRange] = useState([100000, 500000]);
  const minPrice = 50000;
  const maxPrice = 1000000;

  const handlePriceChange = (newRange) => {
    setPriceRange(newRange);
  };
  // bedrooms
  const [activeButtonBedroom, setActiveButtonBedroom] = useState("");

  const handleButtonClickBedroom = (buttonName) => {
    setActiveButtonBedroom(buttonName);
  };
  // bathrooms
  const [activeButtonBathrooms, setActiveButtonBathrooms] = useState("");

  const handleButtonClickBathrooms = (buttonName) => {
    setActiveButtonBathrooms(buttonName);
  };

  // past tenants
  const [activeButtonTenants, setActiveButtonTenants] = useState("");

  const handleButtonClickTenants = (buttonName) => {
    setActiveButtonTenants(buttonName);
  };

  // property type
  const [selectedHouses, setSelectedHouses] = useState("");

  const handleHouseClick = (index) => {
    if (selectedHouses.includes(index)) {
      setSelectedHouses(
        selectedHouses
          .split(", ")
          .filter((item) => item !== index)
          .join(", ")
      );
    } else {
      const updatedHouses =
        selectedHouses.length > 0 ? `${selectedHouses}, ${index}` : `${index}`;
      setSelectedHouses(updatedHouses);
    }
  };



  const [range, setRange] = useState({ min: 5000, max: 500000 });

  const handleSliderChange = (newRange) => {
    setRange(newRange);
  };



  const handleInputMinChange = (event) => {
    const newMin = parseInt(event.target.value, 10);

    // Restrict the minimum value to 50
    const restrictedMin = Math.max(newMin, 0);

    // Set the min value in the range state
    setRange((prevRange) => ({
      ...prevRange,
      min: restrictedMin
    }));
  };


  const handleInputMaxChange = (event) => {
    const newMax = parseInt(event.target.value, 10);

    // Restrict the maximum value to 5000
    const restrictedMax = Math.min(newMax, 500000);

    // Set the max value in the range state
    setRange((prevRange) => ({
      ...prevRange,
      max: restrictedMax
    }));
  };

  const resetRange = () => {
    setRange({ min: 5000, max: 500000 });
  };

  // submit the search
  function handleSearch(e) {
    // prevent form from redirecting to another page
    e.preventDefault();
    // Create an object to store the query parameters
    const queryParams = {
      // landlord_id: landlordId,
    };
    // Add query parameters if the corresponding field is not empty
    if (formData.keyword) {
      queryParams.keyword = formData.keyword;
    }
    if (areaType) {
      queryParams.location = areaType;
    }
    if (countyType) {
      queryParams.county = countyType;
    }

    if (propertyType) {
      queryParams.apartment_type = propertyType;
    }
    // Pass the data entered
    serverUrl
      .get("/landlord/alliance-advance-search", {
        params: queryParams,
      })
      .then((res) => {
        // save the results in localstorage
        localStorage.setItem("searchResults", JSON.stringify(res.data.results));
        if (areaType) {
          localStorage.setItem("countySelected", areaType);

        }
        else {
          localStorage.setItem("countySelected", countyType);

        }

        navigate("/listing-page/All");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const [selectedAmenities, setSelectedAmenities] = useState("");

  const handleEssentialChange = (event, description) => {
    if (event.target.checked) {
      const amenitiesToAdd = description.split(", ").join(","); // Split the description into separate amenities and join them as a string
      setSelectedAmenities((prevAmenities) =>
        prevAmenities ? prevAmenities + "," + amenitiesToAdd : amenitiesToAdd
      );
    } else {
      const amenitiesToRemove = description.split(", ").join(",");
      setSelectedAmenities((prevAmenities) =>
        prevAmenities
          .replace(amenitiesToRemove, "")
          .replace(/,+/g, ",")
          .replace(/^,|,$/g, "")
      ); // Remove the specified amenities and clean up the string
    }
  };

  const [countHouses, setCountHouses] = useState("");

  //   get the count as the data saves its self
  useEffect(() => {
    // Create an object to store the query parameters
    const queryParams = {
    };
    // Add query parameters if the corresponding field is not empty
    if (activeButton) {
      queryParams.rent_type = activeButton;
    }

    if (range && range.max != 5000) {
      queryParams.min_price_range = range.min;
    }

    if (range && range.max < 500000) {
      queryParams.max_price_range = range.max;
    }

    if (activeButtonBedroom) {
      queryParams.bedrooms = activeButtonBedroom;
    }

    if (activeButtonBathrooms) {
      queryParams.bathrooms = activeButtonBathrooms;
    }

    if (activeButtonTenants) {
      queryParams.past_tenants = activeButtonTenants;
    }

    if (selectedHouses) {
      queryParams.property_type = selectedHouses;
    }
    if (propertyType) {
      queryParams.apartment_type = propertyType;
    }
    if (selectedAmenities) {
      queryParams.ammenities = selectedAmenities;
    }
    // Pass the data entered
    serverUrl
      .get("/landlord/alliance-unit-search", {
        params: queryParams,
      })
      .then((res) => {
        // save the results in localstorage
        setCountHouses(res.data.count);
      })
      .catch((error) => {
        console.log(error);
      });
  }, [
    activeButton,
    range,
    activeButtonBedroom,
    activeButtonBathrooms,
    activeButtonTenants,
    selectedHouses,
    propertyType,
    selectedAmenities,
  ]);




  // submit the search
  function handleAdvanced(e) {
    // prevent form from redirecting to another page
    e.preventDefault();
    // Create an object to store the query parameters
    const queryParams = {
    };
    // Add query parameters if the corresponding field is not empty
    if (activeButton) {
      queryParams.rent_type = activeButton;
    }

    if (range && range.max != 5000) {
      queryParams.min_price_range = range.min;
    }

    if (range && range.max < 500000) {
      queryParams.max_price_range = range.max;
    }

    if (activeButtonBedroom) {
      queryParams.bedrooms = activeButtonBedroom;
    }

    if (activeButtonBathrooms) {
      queryParams.bathrooms = activeButtonBathrooms;
    }

    if (activeButtonTenants) {
      queryParams.past_tenants = activeButtonTenants;
    }

    if (selectedHouses) {
      queryParams.property_type = selectedHouses;
    }
    if (propertyType) {
      queryParams.apartment_type = propertyType;
    }
    if (selectedAmenities) {
      queryParams.ammenities = selectedAmenities;
    }
    serverUrl
      .get("/landlord/alliance-unit-search", {
        params: queryParams,
      })
      .then((res) => {
        // save the results in localstorage
        localStorage.setItem(
          "searchResultsHouses",
          JSON.stringify(res.data.results)
        );
        navigate("/selected-property/all");
      })
      .catch((error) => {
        console.log(error);
      });
  }
  const toCamelCase = (str) => {
    return str.replace(/_/g, " ");
  }


  return (
    <div className="homepageSearch">
      <Card style={{ marginInline: "2rem" }}>
        <Form onSubmit={handleSearch}>
          <Card.Body>
            <input
              type="text"
              placeholder="Search .."
              value={formData.keyword}
              onChange={handleChange}
              name="keyword"
              className='d-none'
            />
            <div>
              <select
                value={propertyType}
                onChange={handlePropertyChange}
                className="text-uppercase"
              >
                <option value="" selected disabled>
                  Property Type
                </option>


                {uniquePropertyTypes && uniquePropertyTypes.map((type, index) => (
                  type !== 0 && (
                    <option
                      key={index}
                      value={type}
                      className="text-uppercase"
                    >
                      {typeof type === 'string' ? toCamelCase(type) : type}
                    </option>
                  )
                ))}


                <input
                  type="text"
                  className="d-none"
                  placeholder="Property Type"
                  value={propertyType}
                  readOnly
                />
              </select>
            </div>

            <div>
              <select
                value={countyType}
                onChange={handleCountyChange}
                className="text-uppercase"
              >
                <option value="" selected disabled>
                  County
                </option>

                {counties.map((type, index) => (
                  <option
                    key={index}
                    value={type.county_name}
                    className="text-uppercase"
                  >
                    {type.county_name}
                  </option>
                ))}
                <input
                  type="text"
                  className="d-none"
                  placeholder="Property Type"
                  value={countyType}
                  readOnly
                />
              </select>
            </div>

            <div className={countyType ? '' : 'd-none'}>
              <select
                value={areaType}
                onChange={handleAreaChange}
                className="text-uppercase"
              >
                <option value="" selected disabled>
                  Area
                </option>

                {areaProperty.map((type, index) => (
                  <option
                    key={index}
                    value={type.location}
                    className="text-uppercase"
                  >
                    {type.location}
                  </option>
                ))}
                <input
                  type="text"
                  className="d-none"
                  value={areaType}
                  readOnly
                />
              </select>
            </div>



            <Dropdown
              className="btn-sm btn-success"
              onClick={handleShowModal}
            >
              <Dropdown.Toggle
                id="dropdown-basic"
                className="advanceButtonSearch text-uppercase custom-dropdown-toggle"
              >
                Advanced
              </Dropdown.Toggle>
            </Dropdown>
            <Button
              type="submit"
              className="btn btn-success text-white border-0  text-uppercase  text-decoration-none d-flex justify-content-center align-items-center searchButtonHome"
            >
              Search

            </Button>
          </Card.Body>
        </Form>
      </Card>
      {/* Modal for filtering*/}

      <Modal
        className="modal-lg"
        show={showModal}
        onHide={handleCloseModal}
        style={{ maxWidth: "100%" }}
      >
        {" "}
        <Modal.Header closeButton>
          <h4 style={{ margin: "0 auto", paddingLeft: "20%" }}>Filters</h4>
        </Modal.Header>
        <Form onSubmit={handleAdvanced}>
          <Modal.Body>
            <div>
              <h4 className="mt-1">Price range</h4>
              <div>
                <InputRange
                  minValue={5000}
                  maxValue={500000}
                  step={5000}
                  value={range}
                  onChange={handleSliderChange}
                  style={{ width: "90%", margin: "0 auto" }}
                />
                <div className="histogram">
                  <div className="bar">
                    <div
                      className="range"
                      style={{
                        width: `${((range.max - range.min) / 100) * 100}%`,
                        marginLeft: `${(range.min / 100) * 100}%`,
                        backgroundColor: "yellow",
                      }}
                    ></div>
                  </div>
                </div>
                <div
                  className="input-group mt-3 rentFilterSearchDisplay"
                  style={{

                  }}
                >
                  <span className="currency-label">Ksh</span>
                  <input
                    type="number"
                    className="input-range_input"
                    value={range.min}
                    onChange={handleInputMinChange}
                    style={{ marginRight: "0.5rem", width: "auto" }}
                  />
                  <span className="currency-label">Ksh</span>
                  <input
                    type="number"
                    value={range.max}
                    onChange={handleInputMaxChange}
                    style={{ width: "auto", marginBottom: "1rem" }}
                  />
                  <Button
                    onClick={resetRange}
                    className="btn btn-warning text-white ms-3"
                    style={{ borderRadius: "3px" }}
                  >
                    Reset
                  </Button>
                </div>
              </div>

              <h4 className="mt-5">Property type</h4>
              <Row className="mb-2">
                {items.map((item, index) => (
                  <Col
                    md={3}
                    key={index}
                    className={`item-card ${selectedHouses.includes(item.name)
                      ? "activePropertyType"
                      : ""
                      }`}
                    onClick={() => handleHouseClick(item.name)}
                  >
                    <Image src={item.image} />
                    <p>{item.description}</p>
                  </Col>
                ))}
              </Row>

              <h4 className="mt-5">Bathrooms and Bedrooms</h4>
              <p className="mt-3">Bedrooms</p>

              <div className="d-flex justify-content-between">
                <Button
                  style={{ width: "17%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBedroom === ""
                    ? "activeBedroom"
                    : "inactiveBedroom"
                    }`}
                  onClick={() => handleButtonClickBedroom("")}
                >
                  Any
                </Button>
                <Button
                  style={{ width: "17%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBedroom === "0"
                    ? "activeBedroom"
                    : "inactiveBedroom"
                    }`}
                  onClick={() => handleButtonClickBedroom("0")}
                >
                  0
                </Button>
                <Button
                  style={{ width: "17%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBedroom === "1"
                    ? "activeBedroom"
                    : "inactiveBedroom"
                    }`}
                  onClick={() => handleButtonClickBedroom("1")}
                >
                  1
                </Button>
                <Button
                  style={{ width: "17%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBedroom === "2"
                    ? "activeBedroom"
                    : "inactiveBedroom"
                    }`}
                  onClick={() => handleButtonClickBedroom("2")}
                >
                  2
                </Button>
                <Button
                  style={{ width: "17%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBedroom === "3"
                    ? "activeBedroom"
                    : "inactiveBedroom"
                    }`}
                  onClick={() => handleButtonClickBedroom("3")}
                >
                  3
                </Button>
                <Button
                  style={{ width: "17%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBedroom === "4"
                    ? "activeBedroom"
                    : "inactiveBedroom"
                    }`}
                  onClick={() => handleButtonClickBedroom("4")}
                >
                  4
                </Button>
                <Button
                  style={{ width: "17%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBedroom === "5"
                    ? "activeBedroom"
                    : "inactiveBedroom"
                    }`}
                  onClick={() => handleButtonClickBedroom("5")}
                >
                  5+
                </Button>
              </div>

              <p className="mt-3">Bathrooms</p>
              <div className="d-flex justify-content-between">
                <Button
                  style={{ width: "17%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBathrooms === ""
                    ? "activeBathroom"
                    : "inactiveBathroom"
                    }`}
                  onClick={() => handleButtonClickBathrooms("")}
                >
                  Any
                </Button>
                <Button
                  style={{ width: "17%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBathrooms === "0"
                    ? "activeBedroom"
                    : "inactiveBedroom"
                    }`}
                  onClick={() => handleButtonClickBathrooms("0")}
                >
                  0
                </Button>
                <Button
                  style={{ width: "17%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBathrooms === "1"
                    ? "activeBathroom"
                    : "inactiveBathroom"
                    }`}
                  onClick={() => handleButtonClickBathrooms("1")}
                >
                  1
                </Button>
                <Button
                  style={{ width: "17%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBathrooms === "2"
                    ? "activeBathroom"
                    : "inactiveBathroom"
                    }`}
                  onClick={() => handleButtonClickBathrooms("2")}
                >
                  2
                </Button>
                <Button
                  style={{ width: "17%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBathrooms === "3"
                    ? "activeBathroom"
                    : "inactiveBathroom"
                    }`}
                  onClick={() => handleButtonClickBathrooms("3")}
                >
                  3
                </Button>
                <Button
                  style={{ width: "17%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBathrooms === "4"
                    ? "activeBathroom"
                    : "inactiveBathroom"
                    }`}
                  onClick={() => handleButtonClickBathrooms("4")}
                >
                  4
                </Button>
                <Button
                  style={{ width: "17%", borderRadius: "20px" }}
                  className={`btn btn-transparent bg-transparent btn-outline-dark ${activeButtonBathrooms === "5"
                    ? "activeBathroom"
                    : "inactiveBathroom"
                    }`}
                  onClick={() => handleButtonClickBathrooms("5")}
                >
                  5+
                </Button>
              </div>





           
            </div>
          </Modal.Body>
          <Modal.Footer className="d-flex justify-content-between">
          
            <Button
              variant="warning"
              type="submit"
              className="text-white"
              onClick={handleCloseModal}
            >
              Show {countHouses} Units
            </Button>
          </Modal.Footer>
        </Form>
      </Modal>
    </div>
  );
}

export default HomePageSearch;
