import React from 'react'
import { Routes, Route, BrowserRouter, HashRouter } from 'react-router-dom'
import Home from './pages/Home'
import ListingPage from './pages/ListingPage'
import SelectedProperty from './pages/SelectedProperty'
import SelectedHouse from './pages/SelectedHouse'
import HouseLocation from './pages/HouseLocation'
import Allphotos from './pages/Allphotos'
import Profile from './pages/Profile'
import Services from './pages/Services'
import Competitive from './pages/Competitive'
import AllBlogs from './pages/AllBlogs'
import SingleBlog from './pages/SingleBlog'
import AllProperties from './pages/AllProperties'
import ListingProperty from './pages/ListingProperty'
import Login from './pages/auth/Login'
import Register from './pages/auth/Register'
import Favourites from './pages/Favourites'
import Bookings from './pages/Bookings'
import SelectedPropertAlliance from './pages/SelectedPropertAlliance'
import CommingSoon from './pages/CommingSoon'

function Webroutes() {
    return (

        <HashRouter>
            <Routes>
                <Route exact path="/" Component={Home} />
                <Route exact path="/listing-page/:id" Component={ListingPage} />
                <Route exact path="/selected-property/:id" Component={SelectedProperty} />

                <Route exact path="/selected-property-alliance/:id" Component={SelectedPropertAlliance} />

                <Route exact path="/selected-house/:id" Component={SelectedHouse} />
                <Route exact path="/map/:id" Component={HouseLocation} />
                <Route exact path="/all-photos" Component={Allphotos} />
                <Route exact path="/all-photos-propert/:id" Component={Allphotos} />
                <Route exact path="/unit/:id" Component={Allphotos} />
                <Route exact path='/services' Component={Services} />
                <Route exact path='/competitive' Component={Competitive}/>
                <Route exact path="/profile" Component={Profile} />
                <Route exact path="/all-blogs" Component={AllBlogs} />
                <Route exact path="/listing-properties" Component={ListingProperty} />
                <Route exact path="/all-properties" Component={AllProperties} />
                <Route exact path="/single-blog/:id" Component={SingleBlog} />
                <Route exact path="/login" Component={Login} />
                <Route exact path="/register" Component={Register} />
                <Route exact path="/favourites" Component={Favourites} />
                <Route exact path="/bookings" Component={Bookings} />
                <Route exact path="/comming-soon" Component={CommingSoon} />


            </Routes>
        </HashRouter>


    )
}

export default Webroutes