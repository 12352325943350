import React from 'react';
import '../assets/scss/CommingSoon.scss';
import Comming from '../assets/images/comming_soon.jpg';

const CommingSoon = () => {
    return (
        <div className="coming-soon-page text-center d-flex align-items-center justify-content-center">
            <div className="content">
                <img src={Comming} alt="Logo" className="mb-4 logo" />
                <h1 className="display-4 text-white">Coming Soon</h1>
                <p className="lead text-white">We're working hard to bring you a great experience!</p>
             
            </div>
        </div>
    );
}

export default CommingSoon;
