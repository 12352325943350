import React, { useEffect, useState } from 'react';
import { Map, Marker, InfoWindow, GoogleApiWrapper } from 'google-maps-react';
import { Button, Card, Container, Form, FormControl, Image, Modal, Carousel } from 'react-bootstrap';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/scss/TopMenuFilter.scss'
import '../assets/scss/AllMap.scss'
import { useParams, Link } from "react-router-dom";
import Heart from '../assets/images/heart__1_.png'
import Heart2 from '../assets/images/heart.png'
import { serverUrl, landlordId } from '../serverUrl';
import { jwtDecode } from 'jwt-decode';
import { renderToString } from 'react-dom/server';

import { toast } from "react-toastify";

const PreviousMap = ({ google, locations, coordinates }) => {
    const [mapCenter, setMapCenter] = useState({ lat: 0, lng: 0 });
    const [markers, setMarkers] = useState([]);
    const [openInfoWindowIndex, setOpenInfoWindowIndex] = useState(null);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const [currentUser, setCurrentUser] = useState([])
    useEffect(() => {
        if (coordinates && coordinates.length > 0) {
            // Find the area with the most properties
            const areasWithProperties = {};
            coordinates.forEach(location => {
                const area = location.area;
                areasWithProperties[area] = (areasWithProperties[area] || 0) + 1;
            });
            const maxPropertiesArea = Object.keys(areasWithProperties).reduce((prev, current) =>
                areasWithProperties[prev] > areasWithProperties[current] ? prev : current
            );
            const propertiesInMaxArea = coordinates.filter(location => location.area === maxPropertiesArea);

            if (propertiesInMaxArea.length > 0) {
                const averageLat = propertiesInMaxArea.reduce((sum, location) => sum + location.latitude, 0) / propertiesInMaxArea.length;
                const averageLng = propertiesInMaxArea.reduce((sum, location) => sum + location.longitude, 0) / propertiesInMaxArea.length;

                setMapCenter({ lat: averageLat, lng: averageLng });

                const mapMarkers = coordinates.map((location, index) => (
                    <Marker
                        key={index}
                        position={{ lat: location.latitude, lng: location.longitude }}
                        name={`House Location - Min Rent: ${location.min_rent}`}
                        onClick={() => console.log('Marker Clicked')}
                    />
                ));

                setMarkers(mapMarkers);
            } else {
                setMapCenter({ lat: coordinates[0].latitude, lng: coordinates[0].longitude });
            }
        }
        if (accessToken) {
            // check first if user is logged in
            const parsedTokens = JSON.parse(accessToken);
            const access = parsedTokens.access;
            // headers access token
            const config = {
                headers: { Authorization: `Bearer ${access}` }
            }
            // decoding the token so that i can get the user id
            const decodedToken = jwtDecode(accessToken);
            const userId = decodedToken.user_id;
            // get the current user
            serverUrl.get(`/users/users-details/${userId}/`, config)
                .then((res => {
                    setCurrentUser(res.data)
                }))
                .catch((error) => {
                    console.log(error)
                })
            // Fetch liked units for the user based on their userId.
            serverUrl
                .get(`/landlord/viewers-favourites/?user=${userId}`, config)
                .then((res) => {
                    // Process the response to determine which units are liked by the user.
                    const likedApartment = res.data.results.map((likedApartment) => likedApartment.apartment);


                    // Update the heartState based on the likedUnits.
                    const newHeartState = { ...heartState };
                    likedApartment.forEach((apartmentId) => {
                        newHeartState[apartmentId] = true;
                    });
                    setHeartState(newHeartState);

                })
                .catch((error) => {
                    console.log(error);
                });
        }

    }, []);

    const renderCarouselItems = (apartmentId) => {
        const uniqueImages = coordinates.filter((apartment) => apartment.id === apartmentId)
        const Images = uniqueImages[0].property_image
        return Images.map((imageUrl, index) => (
            <Slider {...settings}>
                <Image src={imageUrl.image} alt={`Image ${index + 1}`} fluid className='image-house-map' />
            </Slider>
        ));
    };

    const accessToken = localStorage.getItem("authTokens");

    const handleMarkerClick = (index) => {
        setOpenInfoWindowIndex(index);

    };
    const handleInfoWindowClose = () => {
        setOpenInfoWindowIndex(null);
    };

    const [heartState, setHeartState] = useState({});
    const toggleHearts = async (apartmentId) => {
        console.log('Clicjed me ')
        const prevHeartStateValue = heartState[apartmentId];
        setHeartState((prevHeartState) => ({
            ...prevHeartState,
            [apartmentId]: !prevHeartState[apartmentId],
        }));
        if (accessToken) {
            const parsedTokens = JSON.parse(accessToken);
            const access = parsedTokens.access;
            const config = {
                headers: { Authorization: `Bearer ${access}` }
            };
            const decodedToken = jwtDecode(accessToken);
            const userId = decodedToken.user_id;
            if (prevHeartStateValue) {
                try {
                    const res = await serverUrl.get(`/landlord/viewers-favourites/?apartment=${apartmentId}&user=${userId}`, config);
                    if (res.data.count === 1) {
                        await serverUrl.delete(`/landlord/viewers-favourites/${res.data.results[0].id}`, config);
                    }
                } catch (error) {
                    console.log(error);
                }
            } else {
                const likedApartment = {
                    apartment: apartmentId,
                    user: userId,
                    user_name: currentUser.full_name
                };
                try {
                    await serverUrl.post(`/landlord/viewers-favourites/`, likedApartment, config);
                } catch (error) {
                    console.log(error);
                }
            }
        } else {
            toast.error('Kindly Login so that you can be able to like apartment');
        }
    };



    // CustomLabelSVG component to create a custom SVG icon
    const CustomLabelSVG = ({ text, backgroundColor }) => (
        <svg
            width="70"
            height="30"
            xmlns="http://www.w3.org/2000/svg"
        >
            <rect id="rounded-rectangle"
                height="20"
                width="60"
                stroke={backgroundColor}
                rx="15"
                stroke-width="1"
                fill={backgroundColor}
            />

            <text
                x="40%"
                y="40%"
                dominantBaseline="middle"
                textAnchor="middle"
                fontSize="9px"
                fontWeight="bold"
                fill="black"
                fontFamily='"Axiforma", sans-serif'
            >
                {text}
            </text>
        </svg>
    );

    function toCamelCase(str) {
        return str.replace(/_/g, ' ');
    }
    return (
        <div style={{ width: '100%', height: '100%', position: 'static' }}>
            <Map
                google={google}
                className='position-relative'
                zoom={11}
                center={mapCenter}
                style={{ height: '100vh', width: '100%', position: 'relative' }}

            >
                {coordinates.map((location, index) => (
                    <Marker
                        key={index}
                        position={{ lat: location.latitude, lng: location.longitude }}
                        icon={{
                            url: `data:image/svg+xml,${encodeURIComponent(renderToString(<CustomLabelSVG text={`Ksh. ${location.min_rent.toLocaleString()}`} backgroundColor="#F9C81A " />))}`,
                            scaledSize: new window.google.maps.Size(80, 50),
                        }}
                        onClick={() => handleMarkerClick(index)}

                    />

                ))}
                {coordinates.map((location, index) => (
                    <InfoWindow
                        key={index}
                        visible={openInfoWindowIndex === index}
                        position={{ lat: location.latitude, lng: location.longitude }}
                        onClose={handleInfoWindowClose}
                    >
                        <Card key={location.id} className='house-map'>
                            <a href={`#/selected-property/${location.id}`} target="_blank" className='text-decoration-none text-dark'>

                                <Card.Body>

                                    <div className="image-container">

                                        <Slider {...settings} interval={null}>
                                            {renderCarouselItems(location.id)}
                                        </Slider>

                                        <div className='heartBadge' onClick={() => toggleHearts(location.id)}>
                                            {heartState[location.id] ? (
                                                <img src={Heart} alt="Heart" />
                                            ) : (
                                                <img src={Heart2} alt="Heart2" />
                                            )}
                                        </div>

                                    </div>
                                    <div className='d-flex topMapButtons justify-content-between' style={{ left: "5%", width: "5vw" }}>
                                        {location.min_bedroom === location.max_bedroom ?
                                            <span className='topRentProperties'>{location.min_bedroom}Br</span>
                                            :
                                            <>
                                                <span className='topRentProperties'>{location.min_bedroom}Br</span>
                                                <span className='topRentProperties'>{location.max_bedroom} Br</span>
                                            </>
                                        }

                                    </div>

                                    <div className='d-flex topMapButtonsLast'>


                                    </div>

                                    <div className='d-flex justify-content-between'>
                                        <p className='text-start mt-4 w-100' style={{ fontSize: "1.2rem", fontWeight: "normal" }}>{location.location},{location.county}</p>
                                    </div>
                                    {location.type_of_apartment && (
                                        <p className='text-capitalize houseName' style={{ textAlign: "left", fontSize: "1.2rem", fontWeight: "normal" }}>{toCamelCase(location.type_of_apartment)}</p>
                                    )}


                                    <p className='houseName' style={{ textAlign: "left", fontSize: "1.5rem", fontWeight: "bold" }}>{location.name}</p>
                                    <p></p>
                                </Card.Body>
                            </a>
                        </Card>

                    </InfoWindow>
                ))}
            </Map>
        </div>
    );
};

export default GoogleApiWrapper({
    apiKey: 'AIzaSyBs50rN-7q3YU8ZfGa9hMyNGGZCTyiWU0s'
})(PreviousMap);
