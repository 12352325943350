import React, { useState, useEffect } from 'react';
import { Container, Nav, Navbar, Col, Form, Carousel, Button } from 'react-bootstrap';
import { NavLink } from 'react-router-dom';
import 'react-input-range/lib/css/index.css'; // Import the CSS for the input range
import InputRange from 'react-input-range';
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/scss/SideMenu.scss';

function SideMenu() {
    const [sliderValue, setSliderValue] = useState({ min: 5000, max: 750000 });
    const [userInput, setUserInput] = useState({ min: '', max: '' });

    // Function to handle slider value change
    const handleSliderChange = (value) => {
        setSliderValue(value);
    };

    // Function to handle user input change
    const handleInputChange = (event) => {
        const { name, value } = event.target;
        setUserInput((prevInput) => ({
            ...prevInput,
            [name]: value,
        }));
    };
    const items = [
        {
            id: 1,
            title: 'Item 1',
            icon: 'house-door',
        },
        {
            id: 2,
            title: 'Item 2',
            icon: 'building',
        },
        {
            id: 3,
            title: 'Item 3',
            icon: 'car',
        },
        {
            id: 4,
            title: 'Item 4',
            icon: 'calendar',
        },
        {
            id: 5,
            title: 'Item 5',
            icon: 'camera',
        },
        {
            id: 1,
            title: 'Item 1',
            icon: 'house-door',
        },
        {
            id: 2,
            title: 'Item 2',
            icon: 'building',
        },
        {
            id: 3,
            title: 'Item 3',
            icon: 'car',
        },
        {
            id: 4,
            title: 'Item 4',
            icon: 'calendar',
        },
        {
            id: 5,
            title: 'Item 5',
            icon: 'camera',
        },
    ];
    const [index, setIndex] = useState(0);

    const handleSelect = (selectedIndex, e) => {
        setIndex(selectedIndex);
    };

    const items1 = items.slice(0, 5); // First 5 items
    const items2 = items.slice(5, 10); // Next 5 items

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 5, // Display 5 items at a time
        slidesToScroll: 5, // Scroll 5 items at a time
    };
    return (
        <div fluid className="SideBar">

            <div className={`sidebar`}>


                <Slider {...settings}>
                    {items.map((item, index) => (
                        <div key={index}>
                            <div className="item-card bg-danger">
                                <span>{item.title}</span>
                            </div>
                        </div>
                    ))}
                </Slider>

                {/* <Form.Select>
                    <option disabled selected>Location</option>
                    <option>Ruaka</option>
                    <option>Ngara</option>
                    <option>Bururburu</option>
                </Form.Select> */}

                {/* {window.location.pathname.includes('/listing-page') ? (
                    <Form.Select>
                        <option disabled selected>Type of Property</option>
                        <option>Residential</option>
                        <option>Commercial</option>
                    </Form.Select>
                ) : (
                    <Form.Select className="custom-select">
                        <option disabled selected>Type of Unit</option>
                        <option>Studio</option>
                        <option>Bungalow</option>
                        <option>Bedistter</option>
                        <option>Servant Quarters(SQ)</option>
                        <option>Mansionette</option>
                        <option>Office</option>
                        <option>Stall</option>
                        <option>Shop</option>
                    </Form.Select>


                )} */}

                {/* <Form.Select>
                    <option disabled selected>Past Tenants</option>
                    <option>0 Tenants</option>
                    <option>1 Tenant</option>
                    <option>2 Tenants</option>
                    <option>3 Tenants</option>
                    <option>4 Tenants</option>
                    <option>5 Tenants</option>
                    <option>6 Tenants</option>
                    <option>7+ Tenants</option>
                </Form.Select> */}
                {/* 

                <Form.Select>
                    <option disabled selected>Payment Type</option>
                    <option>Rent</option>
                    <option>Rent to own</option>
                    <option>One time purchase</option>
                </Form.Select> */}

                {/* Use react-input-range for the range slider */}
                {/* <p className='rent_range'>Rent Range</p>
                <div className="slider-container">
                    <InputRange
                        minValue={5000}
                        maxValue={750000}
                        value={sliderValue}
                        onChange={handleSliderChange}
                        formatLabel={(value) => `Ksh ${value}`}
                    />
                </div> */}


            </div>
        </div>
    );
}

export default SideMenu;
