import React from 'react'
import { Button, Col, Row } from 'react-bootstrap'
import '../../assets/scss/ServiceValuation.scss'
import { Link } from 'react-router-dom';

function ServiceValuation() {
    return (
        <div className='propertyAsset' id="serviceAssetSection">
            <div className='topasset'>
                <h2>PROPERTY & ASSET VALUATION</h2>
                <p>Our Valuers have gained vast experience in the field of property/asset valuation and have carried out many valuation assignments for a host of clients including large corporate organizations. The types of valuations carried out include:</p>

            </div>
            <Row>
                <Col md={4} sm={12} xm={12} className='custom-col'>
                    <i class="bi bi-hand-thumbs-up-fill"></i>
                    <h2>Valuation for Mortgage Financing</h2>
                    <Link to={'/profile'} className='text-decoration-none'>
                        <Button>CONTINUE</Button>
                    </Link>
                </Col>

                <Col md={4} sm={12} xm={12} className='custom-col'>
                    <i class="bi bi-hand-thumbs-up-fill"></i>
                    <h2>Rental Assessment</h2>
                    <Link to={'/profile'} className='text-decoration-none'>
                        <Button>CONTINUE</Button>
                    </Link>
                </Col>

                <Col md={4} sm={12} xm={12} className='custom-col'>
                    <i class="bi bi-hand-thumbs-up-fill"></i>
                    <h2>Valuation for Advisory Purposes</h2>
                    <Link to={'/profile'} className='text-decoration-none'>
                        <Button>CONTINUE</Button>
                    </Link>
                </Col>
            </Row>

            <Row>
                <Col md={4} sm={12} xm={12} className='custom-col'>
                    <i class="bi bi-hand-thumbs-up-fill"></i>
                    <h2>Valuation for Insurance</h2>
                    <Link to={'/profile'} className='text-decoration-none'>
                        <Button>CONTINUE</Button>
                    </Link>
                </Col>

                <Col md={4} sm={12} xm={12} className='custom-col'>
                    <i class="bi bi-hand-thumbs-up-fill"></i>
                    <h2>Valuation for Plant & Machinery</h2>
                    <Link to={'/profile'} className='text-decoration-none'>
                        <Button>CONTINUE</Button>
                    </Link>
                </Col>


            </Row>
        </div>
    )
}

export default ServiceValuation