import React, { useState, useEffect, useMemo } from 'react'
import '../assets/scss/ListingPage.scss'
import { Button, Card, Container, Form, FormControl, Image, Modal, Carousel } from 'react-bootstrap';
import { useLocation } from 'react-router-dom';

import { Link, useParams } from 'react-router-dom'
import Footer from '../components/Footer';
import NavigationBar from '../components/NavigationBar';
import TopMenuFilter from '../components/TopMenuFilter';
import Heart from '../assets/images/heart__1_.png'
import Heart2 from '../assets/images/heart.png'
import Slider from 'react-slick';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';
import '../assets/scss/TopMenuFilter.scss'
import Star from '../assets/images/star.png'
import Map from '../assets/images/map.png'
import { serverUrl } from '../serverUrl';

function AllProperties(props) {
    const { id } = useParams();
    // loading status
    const location = useLocation();

    const [dataFromChild, setDataFromChild] = useState('');
    // Function to handle data from the child
    const handleChildData = (childData) => {
        setDataFromChild(childData);
    };
    const [dataFromSearch, setDataFromSearch] = useState('');
    // Function to handle data from the child
    const handleSearchData = (searchData) => {
        setDataFromSearch(searchData);
    };

    useEffect(() => {
        // Scroll to the top of the page when the route changes
        window.scrollTo(0, 0);
    }, [location]);

    const [isLoading, setIsLoading] = useState(true)


    const [landlordApartment, setLandlordApartment] = useState([])


    // get the searched data
    useEffect(() => {
        serverUrl.get(`/landlord/all-landlords`)
            .then((res) => {
                setLandlordApartment(res.data.results)

                console.log(res.data.results)
            })
            .catch((error) => [
                console.log(error)
            ])
    }, [])



    // filter based on the property location

    // Create a Set to store unique location status
    const uniquePropertyLocation = new Set();

    // Filter houses array to get unique rent amounts
    const uniqueLocation = landlordApartment.filter((location) => {
        if (!uniquePropertyLocation.has(location.location)) {
            uniquePropertyLocation.add(location.location);
            return true;
        }
        return false;
    });

    // on search of the properties get to list the properties
    const [searchQuery, setSearchQuery] = useState("");
    const [selectedRow, setSelectedRow] = useState(null);

    const handleSearch = (event) => {
        setSearchQuery(event.target.value);
        setSelectedRow(null); // Reset selected row when user starts a new search
    };
    function containsSearchQuery(item, dataFromSearch) {
        const searchableFields = Object.values(item).join(" ").toLowerCase();
        return searchableFields.includes(dataFromSearch.toLowerCase());
    }
    const filteredData = landlordApartment.filter((item) => {
        // Filter by selected property type if dataFromChild is not blank
        if (dataFromChild && item.type_of_apartment !== dataFromChild) {
            return false;
        }

        // Filter by searchQuery if it's not blank
        if (dataFromSearch && !containsSearchQuery(item, dataFromSearch)) {
            return false;
        }
        return true;
    });
    // design the table to display 10 each per table
    const [currentPage, setCurrentPage] = useState(0);
    const itemsPerPage = 8;

    const totalPages = Math.ceil(filteredData.length / itemsPerPage);
    const startIndex = currentPage * itemsPerPage;
    const endIndex = currentPage === totalPages - 1 ? filteredData.length : startIndex + itemsPerPage;
    const displayedItems = filteredData.slice(startIndex, endIndex);

    // randomise the images for my properties
    const [backgroundImage, setBackgroundImage] = useState('');
    useEffect(() => {
        const getRandomImage = () => {
            let storedImage = localStorage.getItem('backgroundImage');
            if (!storedImage) {
                storedImage = '';
            }

            let randomNumber = Math.floor(Math.random() * 10) + 1;
            while (randomNumber === 0 || randomNumber === -1 || storedImage.endsWith(`${randomNumber}.jpg`)) {
                randomNumber = Math.floor(Math.random() * 10) + 1;
            }

            const imageUrl = `https://kodinyumba.app/media/Stock/hd/Property/apartment${randomNumber}.jpg`;
            setBackgroundImage(imageUrl);
            localStorage.setItem('backgroundImage', imageUrl);
            setIsLoading(false);
        };

        getRandomImage();
    }, []);


    // randomise for each apartment
    const [backgroundImageApartment, setBackgroundImageApartment] = useState({});
    const [showIndicators, setShowIndicators] = useState(false);

    const settings = {
        dots: true,
        infinite: false,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
    };
    const toggleIndicators = () => {
        setShowIndicators(!showIndicators);
    };

    useEffect(() => {
        const getRandomImages = () => {
            const usedImages = Object.values(backgroundImageApartment);
            const availableImages = Array.from({ length: 10 }, (_, index) => index + 1);
            const uniqueRandomImages = [];

            while (uniqueRandomImages.length < 5) {
                const randomNumber = Math.floor(Math.random() * availableImages.length);
                const randomImage = availableImages.splice(randomNumber, 1)[0];

                if (!usedImages.includes(randomImage)) {
                    uniqueRandomImages.push(randomImage);
                }
            }

            return uniqueRandomImages.map((imageNumber) =>
                `https://kodinyumba.app/media/Stock/Property/apartment${imageNumber}.jpg`
            );
        };

        const updateBackgroundImages = () => {
            const updatedImages = { ...backgroundImageApartment };

            for (const unitDetail of landlordApartment) {
                const apartmentId = unitDetail.id;
                if (!updatedImages[apartmentId]) {
                    updatedImages[apartmentId] = getRandomImages();
                }
            }
            setBackgroundImageApartment(updatedImages);
        };

        updateBackgroundImages();
    }, [landlordApartment, backgroundImageApartment]);

    const renderCarouselItems = (apartmentId) => {
        // const uniqueImages = backgroundImageApartment[apartmentId] || [];
        const uniqueImages = landlordApartment.filter((apartment) => apartment.id === apartmentId)
        const Images = uniqueImages[0].property_image

        return Images.map((imageUrl, index) => (
            <Slider {...settings}>

                <Image src={imageUrl.image} alt={`Image ${index + 1}`} fluid />

            </Slider>
        ));
    };


    const handlePrevPage = () => {
        setCurrentPage(currentPage - 1);
    };

    const handleNextPage = () => {
        setCurrentPage(currentPage + 1);
    };
    const [heartState, setHeartState] = useState({});


    const toggleHearts = (apartmentId) => {
        setHeartState((prevHeartState) => ({
            ...prevHeartState,
            [apartmentId]: !prevHeartState[apartmentId],
        }));
    };





    return (
        <>
            <NavigationBar onData={handleSearchData} {...props} />
            <TopMenuFilter onData={handleChildData} {...props} />

            <div className='property'>

                <div className='topSelectedProperty' style={{ backgroundImage: `url(${backgroundImage})` }}>
                <p className='properties'> ALL PROPERTIES MANAGED BY US</p>



                </div>
                {/* the cards that have the houses and their properties */}
                <div className='housesCards'>
                    {!displayedItems && landlordApartment.length === 0 ?
                        <p className='notfoundp'>Sorry no property was found that matches the criteria set </p>
                        :
                        displayedItems.map((apartmentDetail) =>

                            <Card key={apartmentDetail.id} className='house'>
                                <Card.Body>

                                    <div className="image-container">
                                        <Link key={apartmentDetail.id} to={`/selected-property/${apartmentDetail.id}`} className=' text-decoration-none'>

                                            <Slider {...settings} interval={null}>{renderCarouselItems(apartmentDetail.id)}</Slider>
                                        </Link>
                                        <div className='heartBadge' onClick={() => toggleHearts(apartmentDetail.id)}>
                                            {heartState[apartmentDetail.id] ? (
                                                <img src={Heart} alt="Heart" />
                                            ) : (
                                                <img src={Heart2} alt="Heart2" />
                                            )}
                                        </div>

                                        <div className="badge">{apartmentDetail.houses_total} Units</div>
                                        <Link key={apartmentDetail.id} to={`/selected-property/${apartmentDetail.id}`} className=' text-decoration-none'>

                                            <div className='d-flex justify-content-between'>
                                                <p className='text-muted text-start mt-2 w-100' style={{ fontSize: "0.7rem" }}>{apartmentDetail.location},{apartmentDetail.county}</p>
                                            </div>
                                        </Link>

                                    </div>
                                    <p className='houseName' style={{ clear: "left", margin: "0", marginTop: "-1rem" }}>{apartmentDetail.name}</p>
                                    <p className='text-secondary float-start ' style={{ fontSize: "0.9rem" }}>Ksh.{apartmentDetail.rent.toLocaleString()}</p>

                                </Card.Body>
                            </Card>
                        )
                    }



                </div>

                {/* the pagination of the cards */}
                {landlordApartment.length > 8 ?
                    <div className="pagination" style={{ marginBottom: "2rem" }}>
                        <button onClick={handlePrevPage} disabled={currentPage === 0}>
                            &lt;
                        </button>
                        <span>{`${startIndex + 1}-${endIndex} of ${landlordApartment.length}`}</span>
                        <button onClick={handleNextPage} disabled={currentPage === totalPages - 1}>
                            &gt;
                        </button>
                    </div>

                    :
                    <>
                    </>}

            </div >
            {/* <Button className='text-white bg-dark border-0 bottomButtonMap'>
                <Link to={'/map'} className='text-white text-decoration-none'>
                    Show map
                    <Image src={Map} style={{ width: "20%", marginLeft: "0.2rem" }} />
                </Link>

            </Button> */}

        </>
    )
}

export default AllProperties